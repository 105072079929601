import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, getRequest, postRequest, updateRequest } from '../services';

export const fetchTeachers = createAsyncThunk('teachers/fetchTeachers', async ({pageNumber, pageSize},  { rejectWithValue }) => {
  try {
    const response = await getRequest(`/api/Teacher/paginated?pageNumber=${pageNumber}&pageSize=${pageSize}`);
   
    if (!response.status === 200) {
      throw new Error('Failed to fetch teachers');
    }
    return {
      teachers: response.data?.data?.teachers,
      totalCount: response.data?.data?.totalCount,
      pageSize,
      pageNumber,
    }
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const addTeacher = createAsyncThunk('teachers/addTeacher', async (teacher, {rejectWithValue}) => {
    try {
      const response = await postRequest("/api/Teacher", teacher);
      if (response.status !== 200) {
        return rejectWithValue("Failed to add teacher");
      }
      return response.data?.data; // The payload is the teacher object returned by the API after it is added
    } catch (error) {
      return rejectWithValue(error.message)
    }
});

export const deleteTeacher = createAsyncThunk('teachers/deleteTeacher', async (id, { rejectWithValue }) =>{
 try {
  await deleteRequest(`/api/User/DeleteUser/${id}`);
  return id; // The payload is just the id of the deleted teacher
 } catch (error) {
  return rejectWithValue(error.message);
 }
});

export const updateTeacher = createAsyncThunk(
  "teachers/updateTeacher",
  async ({ id, updatedTeacher }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(`/api/Teacher/${id}`, updatedTeacher);
      if (response.status !== 200 || !response.data?.data) {
        throw new Error("Failed to update teacher");
      }
      return response.data?.data; // Return the updated teacher object
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  teachers: [],
  loading: false, 
  error: null,
  pageSize: 100, // Default pageSize
  totalCount: 10,
  pageNumber: 1,

};

const teachersSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    updateTeacher: (state, action) => {
      const index = state.teachers.findIndex(t => t.id === action.payload.id);
      if (index !== -1) {
        state.teachers[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(addTeacher.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(addTeacher.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.teachers.push(action.payload); // Add the new teacher to the state
      }
    })
    .addCase(addTeacher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Failed to add teacher';
    })
    .addCase(updateTeacher.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(updateTeacher.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.teachers.findIndex((teacher) => teacher.teacher?.id === action.payload?.teacher?.id);
      if (index !== -1) {
        state.teachers[index] = action.payload; // Update the teacher in the state
      }
    })
    .addCase(updateTeacher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Failed to update teacher';
    })
    .addCase(deleteTeacher.pending, (state) => {
      state.loading = true;
      state.error = null;
  })
  .addCase(deleteTeacher.fulfilled, (state, action) => {
      state.loading = false;
      state.teachers = state.teachers.filter((teacher) => teacher.id !== action.payload);  // Filter using ID directly
  })            
  .addCase(deleteTeacher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
  })
      .addCase(fetchTeachers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeachers.fulfilled, (state, action) => {
        state.loading = false; 
        state.teachers = action.payload.teachers || []; // Corrected from 'students' to 'teachers'
        state.totalCount = action.payload.totalCount;
        state.pageNumber = action.payload.pageNumber;
        state.pageSize = action.payload.pageSize;
      })      
      .addCase(fetchTeachers.rejected, (state, action) => {
        state.loading = false; // Set loading to false if there's an error
        state.error = action.error.message; // Capture error message
      });
  },
});

export const {setPageNumber } = teachersSlice.actions;

export default teachersSlice.reducer;
