import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Pencil, Trash2, Plus, ChevronUp, ChevronDown } from "lucide-react";
import AddEmployeeModal from "../../modals/Hrms/AddEmployeeModal";
import { uploadImage } from "../../utils";
import EmployeeDetailsCard from "../../modals/Hrms/EmployeeDetailsCard";

const EmloyeeDetails = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [locationFilter, setLocationFilter] = useState("");

  const cardsData = [
    { title: "Total Emp.", value: 16, icon: "👥", bgColor: "bg-green-100" },
    { title: "Registered", value: 10, icon: "🛡️", bgColor: "bg-blue-100" },
    { title: "Contractual", value: 0, icon: "✍️", bgColor: "bg-purple-100" },
    { title: "Suspended", value: 0, icon: "🚫", bgColor: "bg-orange-100" },
    { title: "Terminated", value: 1, icon: "❌", bgColor: "bg-red-100" },
    { title: "Resigned", value: 1, icon: "🚶‍♂️", bgColor: "bg-gray-300" },
  ];

  const [employees, setEmployees] = useState([
    {
      id: 1,
      firstName: "John",
      lastName: "Doe",
      fatherName: "Robert Doe",
      gender: "Male",
      maritalStatus: "Single",
      email: "john.doe@example.com",
      phone: "1234567890",
      location: "New York",
      cnic: "1985-01-15",
      dob: "1985-01-15",
      emergencyContact: "9876543210",
      contactRelationShip: "Brother",
      address: "123 Main Street, New York",
      religion: "Islam",
      profilePicture: "https://via.placeholder.com/150",
      isActive: true,
    },
    {
      id: 2,
      firstName: "Jane",
      lastName: "Smith",
      fatherName: "John Smith",
      gender: "Female",
      maritalStatus: "Married",
      email: "jane.smith@example.com",
      phone: "9876543210",
      location: "California",
      cnic: "1990-04-25",
      dob: "1990-04-25",
      emergencyContact: "1234567890",
      contactRelationShip: "Sister",
      address: "456 Another St, California",
      religion: "Christianity",
      profilePicture: "https://via.placeholder.com/150",
      isActive: true,
    },
  ]);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null);
  const [currentPicture, setCurrentPicture] = useState(null);

  const onSubmit = async (data, selectedFile) => {
    let fileUrl = null;
    if (selectedFile) {
      fileUrl = await uploadImage(selectedFile);
    } else if (isEdit) {
      fileUrl = currentPicture;
    }

    const newEmployee = {
      id: isEdit ? currentEmployeeId : Date.now(),
      firstName: data.firstName,
      lastName: data.lastName,
      fatherName: data.fatherName,
      gender: data.gender,
      maritalStatus: data.maritalStatus,
      email: data.email,
      phone: data.phone,
      location: data.location,
      cnic: data.cnic,
      dob: data.dob,
      emergencyContact: data.emergencyContact,
      contactRelationShip: data.contactRelationShip,
      address: data.address,
      religion: data.religion,
      profilePicture: fileUrl || "https://via.placeholder.com/150",
      isActive: data.isActive,
    };

    if (isEdit) {
      setEmployees(
        employees.map((emp) =>
          emp.id === currentEmployeeId ? newEmployee : emp
        )
      );
    } else {
      setEmployees([...employees, newEmployee]);
    }

    reset();
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (id) => {
    const employee = employees.find((emp) => emp.id === id);
    if (!employee) {
      console.error("Employee not found for ID:", id);
      return;
    }

    // Pre-fill the form fields for editing
    setValue("firstName", employee.firstName);
    setValue("lastName", employee.lastName);
    setValue("fatherName", employee.fatherName);
    setValue("gender", employee.gender);
    setValue("maritalStatus", employee.maritalStatus);
    setValue("email", employee.email);
    setValue("phone", employee.phone);
    setValue("location", employee.location);
    setValue("cnic", employee.cnic);
    setValue("dob", employee.dob);
    setValue("emergencyContact", employee.emergencyContact);
    setValue("contactRelationShip", employee.contactRelationShip);
    setValue("address", employee.address);
    setValue("religion", employee.religion);
    setValue("isActive", employee.isActive);

    // Set the current employee ID for the edit operation
    setCurrentEmployeeId(id);
    setCurrentPicture(employee.profilePicture);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setEmployees(employees.filter((emp) => emp.id !== id));
    toast.success("Employee deleted successfully");
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const filteredEmployees = employees.filter((employee) => {
    const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase();
    const searchMatch =
      fullName.includes(searchTerm.toLowerCase()) ||
      employee.id.toString().includes(searchTerm);
    const locationMatch =
      locationFilter === "" || employee.location === locationFilter;
    return searchMatch && locationMatch;
  });

  // Extract unique locations for the dropdown
  const uniqueLocations = [
    ...new Set(employees.map((employee) => employee.location)),
  ];

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-6">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={`flex items-center justify-between p-4 rounded shadow`}
          >
            <div className="flex flex-col space-y-2">
              <span className="text-2xl font-bold">{card.value}</span>
              <span className="text-sm text-gray-700">{card.title}</span>
            </div>
            <div className={`text-xl p-2 rounded-full ${card.bgColor}`}>
              {card.icon}
            </div>
          </div>
        ))}
      </div>

      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
  {/* Search Bar */}
  <input
    type="text"
    className="p-2 border border-gray-300 rounded w-full md:w-3/6"
    placeholder="Search by name or ID"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />

  {/* Dropdown Filter */}
  <select
    className="p-2 border border-gray-300 rounded w-full md:w-2/6"
    value={locationFilter}
    onChange={(e) => setLocationFilter(e.target.value)}
  >
    <option value="">All Locations</option>
    {uniqueLocations.map((location, index) => (
      <option key={index} value={location}>
        {location}
      </option>
    ))}
  </select>

  {/* Add Employee Button */}
  <button
    className="w-full md:w-1/6 flex items-center justify-center bg-[#241763] text-white p-2 rounded"
    onClick={() => {
      setIsModalOpen(true);
      setIsEdit(false);
      reset();
    }}
  >
    <Plus className="h-4 w-4" />
    <span className="ml-2">Add Employee</span>
  </button>
</div>


      {/* Modal for Adding/Editing employee */}
      <AddEmployeeModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        setValue={setValue}
        reset={reset}
        currentPicture={currentPicture}
      />

      <div className="overflow-x-auto overflow-visible">
        <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
          <table className="table-auto w-full mt-6">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>Profile</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Email</th>
                <th>Phone</th>
                <th>DOB</th>
                <th>Location</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {filteredEmployees.map((employee, index) => (
                <React.Fragment key={employee.id}>
                  <tr className="p-2 py-2 hover:bg-gray-200 space-y-2">
                    <td>
                      <img
                        src={employee.profilePicture}
                        alt="Employee Profile"
                        className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                      />
                    </td>
                    <td>{`${employee.firstName} ${employee.lastName}`}</td>
                    <td>{employee.gender}</td>
                    <td>{employee.email}</td>
                    <td className="text-blue-500">{employee.phone}</td>
                    <td>{new Date(employee.dob).toLocaleDateString()}</td>
                    <td>{employee.location}</td>
                    <td>{employee.isActive ? "Yes" : "No"}</td>
                    <td className="space-x-2 flex items-center py-2">
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                        onClick={() => handleEdit(employee.id)}
                      >
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleDelete(employee.id)}
                      >
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>

                  {/* Extended Section */}
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="9">
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-inner mt-2">
                          <EmployeeDetailsCard />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default EmloyeeDetails;
