import React from "react";
import { X } from "lucide-react";

const AddWarningModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
}) => {
  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-11/12 md:w-2/3 lg:w-1/2 h-3/4 overflow-hidden rounded shadow-lg relative">
        {/* Modal Header */}
        <div className="flex justify-between items-center border-b pb-2 px-4">
          <h2 className="text-xl font-semibold">
            {isEdit ? "Edit warning" : "Add Warning"}
          </h2>
          <button
            className="p-2 hover:bg-gray-200 rounded-full"
            onClick={() => {
              setIsModalOpen(false);
              reset();
            }}
          >
            <X />
          </button>
        </div>

        {/* Modal Content with Scrolling */}
        <div className="overflow-y-auto px-6 py-4 h-[calc(100%-60px)]">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data)
            )}
          >
         

            {/* First Name & Last Name */}
            <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
                <label className="block text-gray-700 font-medium">
                  Select Employee Name
                </label>
                <select
                  {...register("gender", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                >
                  <option value="Male">Amjad</option>
                  <option value="Female">Sfdar</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                 Name
                </label>
                <input
                  type="text"
                  {...register("name", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter last name"
                />
              </div>
            </div>

            {/* Department & Designation */}

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-medium">
                 Department
                </label>
                <input
                  type="text"
                  {...register("department", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter first name"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  Designation
                </label>
                <input
                  type="text"
                  {...register("designation", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter designation"
                />
              </div>
            </div>
    
            {/* Location & CNIC */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-medium">
                  Location
                </label>
                <input
                  type="text"
                  {...register("location", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter location"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">Warning Date</label>
                <input
                  type="date"
                  {...register("warningDate", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Warning date"
                />
              </div>
            </div>

            {/* Is Active */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">
                Active Status
              </label>
              <input
                type="checkbox"
                {...register("isActive")}
                className="mt-2"
              />
            </div>

            <div>
                <label className="block text-gray-700 font-medium">
                  Subject
                </label>
                <select
                  {...register("gender", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                >
                  <option value="subject">Miscounduct</option>
                  <option value="subject">No show</option>
                </select>
              </div>

              <textarea
                {...register('description', { required: 'Description is required' })}
                placeholder="Description"
                className="p-2 border border-gray-300 rounded-md w-full mb-4"
                rows="4"
              ></textarea>
            

            {/* Modal Actions */}
            <div className="flex justify-end gap-4">
              <button
                type="button"
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                onClick={() => {
                  setIsModalOpen(false);
                  reset();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#241763] text-white px-4 py-2 rounded"
              >
                {isEdit ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddWarningModal;

