import React, { useState } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp, Eye } from "lucide-react";
import FeeBillsModal from "../../../modals/admin/FeeBillsModal";


function HrApproval({ feeBills, setFeeBills }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
   const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({
    studentName: "",
    feeCode: "",
    status: "",
  });

  // Dummy data
  const dummyFeeBills = [

    {
      studentId: "003",
      studentName: "Sam Wilson",
      feeCode: "FC103",
      billingCycleDate: "2024-03-15",
      feeBills: "Yearly",
      status: "Overdue",
      invoice: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    },
  ];

  const handleHRApproval = () => {
    // Example logic - Replace with actual functionality
    console.log("Proceeding for Finance Approval...");
    alert("Proceeding for HR Approval...");
  };

  // Handle Search Input Change
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    console.log("Searching:", event.target.value);
  };

  const [feeBillsData, setFeeBillsData] = useState(dummyFeeBills);

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const filteredFeeBills = feeBillsData.filter(
    (bill) =>
      (filters.studentName === "" ||
        bill.studentName.includes(filters.studentName)) &&
      (filters.feeCode === "" || bill.feeCode.includes(filters.feeCode)) &&
      (filters.status === "" || bill.status.includes(filters.status))
  );

  return (
    <div>
    <div className="flex w-full items-center justify-between mt-4 space-x-2">
        <span className="text-gray-700 font-medium">Session: February-2024</span>
        <input
          type="text"
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Search By Emp. Name / Code"
          className="border border-gray-300 rounded p-2 w-1/4"
        />
        <button
          onClick={handleHRApproval}
          className="px-4 py-2 bg-[#241763] text-white rounded"
        >
          Proceed for Finance Approval
        </button>
      </div>

      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr className="border-b bg-[#241763]/10">
            <th className="p-2">Emp code</th>
              <th>Emp Name</th>
              <th>Session</th>
              <th>Ded.</th>
              <th>OT</th>
              <th>Ded.</th>
              <th>B.Salary</th>
              <th>Ded.Amount</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {/* {filteredFeeBills.length > 0 ? (
              filteredFeeBills.map((bill, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-200">
                    <td>{bill.studentId}</td>
                    <td>{bill.studentName}</td>
                    <td>{bill.feeCode}</td>
                    <td>{bill.billingCycleDate}</td>
                    <td>{bill.feeBills}</td>
                    <td>{bill.status}</td>
                    <td className="text-center">
                      <span
                        onClick={() => 
                          window.open(
                            bill.invoice,
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                        className="text-[#241763]"
                      >
                        <Eye className="w-5 h-5 ml-2" />
                      </span>
                    </td>

                    <td className="space-x-2 flex items-center py-2">
                      <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group">
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group">
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="8">
                        <div className="bg-gray-100 p-4">
                          <div>Additional details about the bill...</div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center p-4">
                  No Fee Bills Found
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>

      {isModalOpen && <FeeBillsModal />}
    </div>
  );
}

export default HrApproval;
