import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { MoreVertical } from "lucide-react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import NameAvatar from "./NameAvatar";
import TeachersObservation from "../../modals/teacher/TeachersObservation";
import { userSearchSvg } from "../../Constant/svgs";
import { useTranslation } from "react-i18next";
import { postRequest } from "../../services";
import { message } from "antd";

const AttendanceTable = forwardRef((props, ref) => {
  const { myAttendaceData, classData,fetchAttendanceData } = props;
  
  const [openIndexes, setOpenIndexes] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "sa";

  useEffect(() => {
    if (myAttendaceData.students && classData) {
      const newData = myAttendaceData.students.map((student) => ({
        id: student.id,
        name: student.name,
        dp: student.profilePicture,
        section: classData.sectionName || "Unknown",
        attendance: [
          { label: "PP", checked: false },
          { label: "PO", checked: false },
          { label: "A", checked: false },
          { label: "L", checked: false },
        ],
        performance: "80%",
      }));
      setTransformedData(newData);
    }
  }, [myAttendaceData, classData]);

  const toggleDropdown = (index) => {
    setOpenIndexes((prevIndexes) =>
      prevIndexes.includes(index) ? prevIndexes.filter((i) => i !== index) : [index]
    );
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxHeight: "100%",
      overflow: "auto",
    },
  };

  const attendanceTableHeadings = [
    t("studentName"),
    t("Section"),
    t("markAttendance"),
    t("overallPerformance"),
    t("action"),
  ];

  const closeDropdown = (e) => {
    const clickedInsideDropdown = openIndexes.some(
      (index) => e.target.closest(`#dropdownMenu-${index}`) !== null
    );
    if (!clickedInsideDropdown) setOpenIndexes([]);
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, [openIndexes]);

  const items = [
    {
      label: "Teacher's Observation",
      icon: userSearchSvg,
    },
  ];

  const handleClick = (label) => {
    if (label === "Teacher's Observation") setOpen(true);
  };

  const handleAttBtnClick = (index, name) => {
    const updatedData = transformedData.map((student) => {
      if (student.name === name) {
        student.attendance = student.attendance.map((att, i) => ({
          ...att,
          checked: i === index,
        }));

        // Save the selected status to the attendanceData state
        const updatedAttendanceData = attendanceData.filter((data) => data.studentId !== student.id);
        updatedAttendanceData.push({
          studentId: student.id,
          status: student.attendance.find((att) => att.checked).label,
        });
        setAttendanceData(updatedAttendanceData);
      }
      return student;
    });
    setTransformedData(updatedData);
  };

  const createAttendance = (attendance, name) => {
    return attendance.map((item, index) => (
      <button
        key={index}
        className={`${item.checked ? `${item.label.toLowerCase()}BtnActive` : `${item.label.toLowerCase()}Btn`}`}
        onClick={() => handleAttBtnClick(index, name)}
      >
        {item.label}
      </button>
    ));
  };

  const triggerSaveAttendance = async () => {
    const date = new Date();
    const formattedDate = date.toISOString(); 
    const payload = {
      subjectId: classData.subjectId,
      classAssignmentId: classData.id,
      date: formattedDate,
      students: attendanceData
    };
    try {
      const response = await postRequest(`/api/Attendance/mark`, payload);
      if (response.status === 200 && response.data.success) {
        message.success(response.data.message || "Attendance saved successfully");
        fetchAttendanceData();
      } else {
        message.error(response.data.message || "Failed to save lesson");
      }
    } catch (error) {
      console.error("Error:", error.message);
      message.error("An error occurred while saving the lesson");
    }
  };

  useImperativeHandle(ref, () => ({
    triggerSaveAttendance,
  }));
  return (
    <>
      <Modal isOpen={open} style={customStyles} onRequestClose={() => setOpen(false)}>
        <TeachersObservation onRequestClose={() => setOpen(false)} />
      </Modal>
      <div className="container-table100">
        <div className="wrap-table100" dir={isArabic ? "rtl" : "ltr"}>
          <div className="table100 ver5 m-b-110">
            <div className="table100-head">
              <table>
                <thead>
                  <tr className="row100 head">
                    {attendanceTableHeadings.map((head, index) => (
                      <th className={`cell100 column${index + 1}`} key={index}>
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            <div className="table100-body">
              <table>
                <tbody>
                  {transformedData.map((item, index) => (
                    <tr className="row100 body" key={index}>
                      <td className="cell100 column1">
                        <div className="d-flex align-items-center attendanceUser gap-2">
                          {item.dp ? <img src={item.dp} alt="user" /> : <NameAvatar name={item.name} rounded />}
                          {item.name}
                        </div>
                      </td>
                      <td className="cell100 column2">{item.section}</td>
                      <td className="cell100 column3">
                        <div className="d-flex gap-2 generateAtt">
                          {createAttendance(item.attendance, item.name)}
                        </div>
                      </td>
                      <td className="cell100 column4">{item.performance}</td>
                      <td className="cell100 column5">
                        <div className="dropdown d-flex justify-content-end">
                          <div
                            className="iconWrapper"
                            onClick={() => toggleDropdown(index)}
                            id={`dropdownMenu-${index}`}
                          >
                            <MoreVertical />
                          </div>
                          <ul
                            className={`dropdown-menu gradeTableDropdown p-0 py-1 ${openIndexes.includes(index) ? "show" : ""
                              }`}
                          >
                            {items.map((item, idx) => (
                              <li
                                key={idx}
                                className="rounded px-2 m-0"
                                onClick={() => handleClick(item.label)}
                              >
                                <Link className="dropdown-item gap-2 optionLists align-items-center rounded mt-1 d-flex justify-content-between">
                                  <span className="h6 p-0 m-0">{item.label}</span>
                                  <span>{item.icon}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default AttendanceTable;
