import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, postRequest, updateRequest } from "../services";
import toast from "react-hot-toast";

const initialState = {
  students: [],
  loading: false,
  error: null,
  pageSize: 100, // Default pageSize
  totalCount: 10,
  pageNumber: 1,
};

export const fetchStudents = createAsyncThunk(
  "students/fetchStudents",
  async ({ pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const response = await getRequest(
        `/api/Student/paginated?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      if (response.status !== 200) {
        return rejectWithValue("Failed to fetch students");
      }
      return {
        students: response.data?.data?.students,
        totalCount: response.data?.data?.totalCount,
        pageNumber,
        pageSize,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addStudents = createAsyncThunk(
  "students/addStudents",
  async (student, { rejectWithValue }) => {
    try {
      const { data } = await postRequest("/api/Student", student);
      if (data) {
        return data?.data;
      }
      return rejectWithValue("Error in API");
    } catch (error) {
      console.error("Error adding student:", error);
      return rejectWithValue(error.error);
    }
  }
);

export const deleteStudent = createAsyncThunk(
  "students/deleteStudent",
  async (id, { rejectWithValue }) => {
    try {
      await deleteRequest(`/api/User/DeleteUser/${id}`);
      return id; // Return only the ID of the deleted student
    } catch (error) {
      console.error("Error deleting student:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const updateStudent = createAsyncThunk(
  "students/updateStudent",
  async ({ id, updatedStudent }, { rejectWithValue }) => {
    try {
      const response = await updateRequest(
        `/api/Student?id=${id}`,
        updatedStudent
      );
      if (response.status !== 200 || !response.data?.data) {
        throw new Error("Failed to update student");
    }
      return response.data?.data; // Return the updated student object
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const studentSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    updateStudent: (state, action) => {
      const index = state.students.findIndex((s) => s.id === action.payload.id);
      if (index !== -1) {
        state.students[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateStudent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.students.findIndex(
          (student) => student.student?.id === action.payload.student?.id
        );
        if (index !== -1) {
          state.students[index] = action.payload; // Update the student in the state
        }
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update student";
        toast.error(state.error);
      })
      .addCase(deleteStudent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        state.loading = false;
        state.students = state.students.filter(
          (student) => student.id !== action.payload
        ); // Filter using ID directly
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchStudents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        console.log("Fetched students payload:", action.payload); // Log payload structure
        state.loading = false;
        state.students = action.payload.students || [];
        state.totalCount = action.payload.totalCount;
        state.pageNumber = action.payload.pageNumber;
        state.pageSize = action.payload.pageSize;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addStudents.fulfilled, (state, action) => {
        state.loading = false;
        state.students.push(action.payload);
      })
      .addCase(addStudents.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStudents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to add student";
        toast.error(state.error);
      });
  },
});

export const { setPageNumber } = studentSlice.actions;

export default studentSlice.reducer;
