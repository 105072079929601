import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Pencil, Trash2, Plus, ChevronUp, ChevronDown } from "lucide-react";
import AddWarningModal from "../../modals/Hrms/AddWarningModal";

const WarningDetails = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentWarning, setCurrentWarning] = useState(null);

  const [warnings, setWarnings] = useState([]);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const cardsData = [
    {
      title: "Total Warnings.",
      value: 16,
      icon: "‼️",
      bgColor: "bg-green-100",
    },
    { title: "First Warning.", value: 10, icon: "⚠️", bgColor: "bg-blue-100" },
    { title: "Second Warning", value: 0, icon: "⚠", bgColor: "bg-purple-100" },
    { title: "Terminate", value: 0, icon: "🚫", bgColor: "bg-orange-100" },
  ];

  const onSubmit = (data) => {
    if (isEdit && currentWarning) {
      // Update existing warning
      setWarnings((prev) =>
        prev.map((warning) =>
          warning.id === currentWarning.id ? { ...warning, ...data } : warning
        )
      );
      toast.success("Warning updated successfully!");
    } else {
      // Add new warning
      setWarnings((prev) => [
        ...prev,
        { id: Date.now(), ...data, Status: true },
      ]);
      toast.success("Warning added successfully!");
    }
    setIsModalOpen(false);
    reset();
  };

  const handleEdit = (id) => {
    const warningToEdit = warnings.find((warning) => warning.id === id);
    if (warningToEdit) {
      setCurrentWarning(warningToEdit);
      setIsEdit(true);
      setIsModalOpen(true);

      // Populate modal fields
      Object.keys(warningToEdit).forEach((key) => {
        setValue(key, warningToEdit[key]);
      });
    }
  };

  const handleDelete = (id) => {
    setWarnings((prev) => prev.filter((warning) => warning.id !== id));
    toast.success("Warning deleted successfully!");
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const filteredWarnings = warnings.filter((warning) => {
    const searchMatch = searchTerm
      ? `${warning.name}`.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    const locationMatch = locationFilter
      ? warning.location === locationFilter
      : true;
    return searchMatch && locationMatch;
  });

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Dashboard Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-4 rounded shadow"
          >
            <div className="flex flex-col space-y-2">
              <span className="text-2xl font-bold">{card.value}</span>
              <span className="text-sm text-gray-700">{card.title}</span>
            </div>
            <div className={`text-xl p-2 rounded-full ${card.bgColor}`}>
              {card.icon}
            </div>
          </div>
        ))}
      </div>

      {/* Filters and Add Button */}
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
        <input
          type="text"
          className="p-2 border border-gray-300 rounded w-full md:w-3/6"
          placeholder="Search by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Locations</option>
          {/* Add location options */}
        </select>
        <button
          className="w-full md:w-1/6 flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsEdit(false);
            setCurrentWarning(null);
            setIsModalOpen(true);
            reset();
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2 text-sm">Add Warning</span>
        </button>
      </div>

      {/* Warning List Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Emp. Name</th>
              <th>Location</th>
              <th>Warning Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {filteredWarnings.map((warning, index) => (
              <React.Fragment key={warning.id}>
                <tr className="hover:bg-gray-200">
                  <td>{warning.name}</td>
                  <td>{warning.location}</td>
                  <td>{warning.warningDate}</td>
                  <td>{warning.Status ? "Active" : "Inactive"}</td>
                  <td className="space-x-2 flex items-center py-2">
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                      onClick={() => handleEdit(warning.id)}
                    >
                      <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <button
                      className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                      onClick={() => handleDelete(warning.id)}
                    >
                      <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                    </button>
                    <div
                      onClick={() => toggleView(index)}
                      className="cursor-pointer hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                    >
                      {expandedRowIndex === index ? (
                        <ChevronUp
                          size={16}
                          className="group-hover:text-white"
                        />
                      ) : (
                        <ChevronDown
                          size={16}
                          className="group-hover:text-white"
                        />
                      )}
                    </div>
                  </td>
                </tr>
                {expandedRowIndex === index && (
                  <tr>
                    <td colSpan="5">
                      <div className="p-4 bg-gray-100">Details...</div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* Warning Modal */}
      <AddWarningModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        reset={reset}
        currentPicture={null}
      />

      <Toaster />
    </div>
  );
};

export default WarningDetails;
