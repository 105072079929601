import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clockSvg, calanderSvg, studentSvg } from "../../Constant/svgs";
import CustomDropdown from "../../Components/Common/CustomFilter";
import { formatTime } from "../../Constant/date";
import { useTranslation } from "react-i18next";
import { getRequest } from '../../services/index';
import { Spin, message } from 'antd';

const ClassManagement = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [classFilterValues, setClassFilterValues] = useState('');
  const [seccionFilterValues, setSeccionFilterValues] = useState('');
  const [subjFilterValues, setSubjFilterValues] = useState('');
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;
  const [classData, setClassData] = useState([]);
  const userID = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Fetch data and set classData
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getRequest(`/api/ClassAssignment/GetMyClasses?userId=${userID.userId}`);
      const data = response.data.data;
      setClassData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Dynamically extract unique values from classData
  const classFilter = [...new Set(classData.map(exam => exam.className))];
  const sectionFilter = [...new Set(classData.map(exam => exam.sectionName))];
  const subjectFilter = [...new Set(classData.map(exam => exam.subjectName))];

  // Filtered classData based on selected filters
  const filteredExamData = classData.filter(exam => {
    const classMatch = classFilterValues ? exam.className === classFilterValues : true;
    const sectionMatch = seccionFilterValues ? exam.sectionName === seccionFilterValues : true;
    const subjectMatch = subjFilterValues ? exam.subjectName === subjFilterValues : true;
    const searchMatch = searchQuery ? `${exam.className} - ${exam.subjectName}`.toLowerCase().includes(searchQuery.toLowerCase()) : true;

    return classMatch && sectionMatch && subjectMatch && searchMatch;
  });
  const myClasses = (exam) => {
    
    const currentDay = new Date().toLocaleString("en-US", { weekday: "long" });
    const currentTime = new Date().toTimeString().split(" ")[0];
  
    const todaySchedule = exam.schedules.find(
      (schedule) =>
        schedule.day === currentDay &&
        currentTime >= schedule.startTime &&
        currentTime <= schedule.endTime
    );
  
    if (todaySchedule) {
      navigate("/attendance", { state: { ...exam } });
    } else {
      message.warning("Today is not a class day.");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className={`row ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>
          <div className="col-lg-4 mb-3 ">
            <input
              type="text"
              placeholder={t('searchHear')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`w-full p-2 border rounded ${isArabic === "sa" ? "text-end" : "text-start"}`}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mb-3 ">
            <CustomDropdown
              options={classFilter}
              value={classFilterValues}
              onChange={(value) => setClassFilterValues(value)}
              placeholder={t('allClasses')}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mb-3 ">
            <CustomDropdown
              options={sectionFilter}
              value={seccionFilterValues}
              onChange={(value) => setSeccionFilterValues(value)}
              placeholder={t('allSections')}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mb-3 ">
            <CustomDropdown
              options={subjectFilter}
              value={subjFilterValues}
              onChange={(value) => setSubjFilterValues(value)}
              placeholder={t('allSubjects')}
            />
          </div>
        </div>
        <Spin spinning={loading}>
          <div className={`row text-capitalize py-2 ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
            {!loading && filteredExamData?.length === 0 ? (
              <div className="text-center w-100 py-5">
                <h4>{t("No Data Available")}</h4>
              </div>
            ) : (
              filteredExamData?.map((exam, index) => (
                <div
                  className="col-md-6 col-lg-4 cursor-pointer py-2"
                  key={index}
                  onClick={() => myClasses(exam)}
                >
                  <div className="d-flex flex-column align-items-between bg-white examDataWrapper px-2 py-3 borderRadius_15">
                    <div className={`d-flex justify-content-between px-2 ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                      <h4
                        className={`fw-bold p-0 m-0 fs-6 cursor-pointer ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                      >
                        {`${exam.className} - ${exam.subjectName}`}
                      </h4>
                    </div>
                    <div className={`d-flex justify-content-start gap-3 examChipsWrapper mt-3 px-2 ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                      <div className={`examChip ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                        {exam.subjectName}
                      </div>
                      <div className={`examChip ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                        {`${exam.lectures} Lectures`}
                      </div>
                      <div className={`examChip ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                        <div className="examChip">
                          {exam.sectionName?.startsWith("Section")
                            ? exam.sectionName
                            : `Section ${exam.sectionName}`}
                        </div>
                      </div>
                    </div>
                    <div className={`d-flex gap-3 mt-3 align-items-center justify-content-between examSvgsText px-2 ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                      <span className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                        {calanderSvg}{" "}
                        <span className="p-0 m-0">
                          {exam.schedules.map((item, index) => {
                            const dayAbbreviation = item.day.slice(0, 2).toUpperCase();
                            return index < exam.schedules.length - 1 ? `${dayAbbreviation}, ` : dayAbbreviation;
                          })}
                        </span>
                      </span>
                      <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                        {studentSvg}{" "}
                        <span className="m-0 p-0">
                          {exam.student} {t("Students")}
                        </span>
                      </div>
                      <div className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                        {clockSvg}
                        <p className="p-0 m-0 ">{formatTime(exam.schedules[0].startTime, exam.schedules[0].endTime)} {t("Mins")}</p>
                      </div>
                    </div>
                    <div className={`d-flex gap-3 mt-3 align-items-center justify-content-between examSvgsText px-2 ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                      <span className={`d-flex gap-2 align-items-center ${isArabic === "sa" ? 'flex-row-reverse' : ''}`}>
                        {calanderSvg}{" "}
                        <span className="p-0 m-0">{`${exam.startedSession.split("T")[0]}. - ${exam.endSession.split("T")[0]}`}</span>
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </Spin>

      </div>
    </>
  );
};

export default ClassManagement;
