import React from 'react'

const Attendance = () => {
  return (
    <div>
      Attendance
    </div>
  )
}

export default Attendance
