import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import StudentModal from "../../modals/admin/StudentModal";
import toast, { Toaster } from "react-hot-toast";
import {
  ChevronDown,
  ChevronUp,
  Pencil,
  Trash2,
  User,
  SendHorizonal,
} from "lucide-react";
import StudentCards from "../../Components/admin/Cards/StudentCards";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import {
  updateStudent,
  deleteStudent,
  fetchStudents,
  addStudents,
  setPageNumber,

} from "../../redux/studentSlice";
import { fetchClasses, fetchClassesV1} from "../../redux/classSlice";
import { fetchFeeStructures } from '../../redux/feeStructureSlice'
import { Users, UserX, Trophy, Medal } from "lucide-react";
import NotificationsModal from "../../Components/Common/NotificationModal";
import { uploadImage } from "../../utils";

const Students = () => {
  const { reset, register, watch, handleSubmit, setValue } = useForm();
  const password = watch("password");
  const dispatch = useDispatch();
  const students = useSelector((state) => state.students.students);
  const loading = useSelector((state) => state.students.loading);
  const [currentPicture, setCurrentPicture] = useState(null);
  const [selectedClass, setSelectedClass] = useState(""); // Add this in the student component

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationsModal, setIsNotificationsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [currentStudentId, setCurrentStudentId] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [campusFilter, setCampusFilter] = useState("");
  const [standardFilter, setStandardFilter] = useState("");
  const [sectionFilter, setSectionFilter] = useState("");
  const [tileFilter, setTileFilter] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);

  const totalCount = useSelector((state) => state.students.totalCount);
  const pageSize = useSelector((state) => state.students.pageSize);
  const pageNumber = useSelector((state) => state.students.pageNumber);

  const classes = useSelector((state) => state?.classes?.classes || []);
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const { feeStructures } = useSelector((state) => state.feeStructures);

    useEffect(() => {
      dispatch(fetchFeeStructures());
    }, [dispatch]);
   

  useEffect(() => {
    dispatch(fetchStudents({ pageNumber, pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  useEffect(() => {
    dispatch(fetchClassesV1({ pageNumber: 1, pageSize: 100 }));
  }, [dispatch]);

  const topPerformers = ["A", "A+"];
  const studentcardData = [
    {
      title: "Total Students",
      icon: Users,
      number: totalCount || 0,
      status: "",
    },
    {
      title: "Top Performers",
      icon: Trophy,
      number:
        students?.filter((s) =>
          topPerformers.includes(s?.academicDetails?.overallAnnualResult)
        )?.length || 0,
      status: "TP",
    },
    {
      title: "Excellent Attendance",
      icon: Medal,
      number:
        students?.filter(
          (s) => parseFloat(s?.academicDetails?.attendance) >= 90
        )?.length || 0,
      status: "EA",
    },
    {
      title: "Fee Defaulters",
      icon: UserX,
      number: 0,
      status: "FD",
    },
  ];

  const onSubmit = async (data, selectedFile) => {
    let fileUrl = null;
    if (selectedFile) {
      fileUrl = await uploadImage(selectedFile);
    } else if (isEdit) {
      fileUrl = currentPicture;
    }

    const transformedPayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      cnic: data.cnic,
      location: data.location,
      dob: data.dob,
      email: data.email,
      password: data.password,
      gender: data.gender,
      admissionDate: data.admissionDate,
      maritalStatus: data.maritalStatus,
      sectionId: data.sectionId,
      userStatus: data.userStatus,
      userDetails: {
        fatherName: data.userDetails?.fatherName || "",
        fatherCNIC: data.userDetails?.fatherCNIC || "",
        fatherContact: data.userDetails?.fatherContact || "",
        fatherProfession: data.userDetails?.fatherProfession || "",
        motherName: data.userDetails?.motherName || "",
        motherCNIC: data.userDetails?.motherCNIC || "",
        motherContact: data.userDetails?.motherContact || "",
        motherProfession: data.userDetails?.motherProfession || "",
        parentEmail: data.userDetails?.parentEmail || "",
      },
      feeAndDues: {
        monthlyFee: data.feeAndDues?.monthlyFee || 0,
        lmsFee: data.feeAndDues?.lmsFee || 0,
        extraCurricularActFee: data.feeAndDues?.extraCurricularActFee || 0,
        anySpecialDiscount: data.feeAndDues?.anySpecialDiscount || 0,
        siblingDiscount: data.feeAndDues?.siblingDiscount || 0,
        employeeKidDiscount: data.feeAndDues?.employeeKidDiscount || 0,
        userStatus: data.userDetails?.userStatus || 0,
        discountName: data.userDetails?.discountName || 0,
        scholarshipName: data.userDetails?.scholarshipName || 0,

      },
      academicDetails: {
        firstSemResult: data.academicDetails?.firstSemResult || 0,
        secondSemResult: data.academicDetails?.secondSemResult || 0,
        thirdSemResult: data.academicDetails?.thirdSemResult || 0,
        overallAnnualResult: data.academicDetails?.overallAnnualResult || 0,
      },
    };

    if (fileUrl) {
      transformedPayload["profilePicture"] = fileUrl || "";
    }

    if (isEdit) {
      dispatch(
        updateStudent({
          id: currentStudentId,
          updatedStudent: transformedPayload,
        })
      );
    } else {
      dispatch(addStudents(transformedPayload)); // Send FormData to backend
    }

    reset();
    setIsModalOpen(false);
    handleModalClose();
  };

  const handleEdit = (student) => {
    console.log(student);
    if (student) {
      // Pre-fill the form fields for editing
      setValue("firstName", student.student?.firstName);
      setValue("lastName", student.student?.lastName);
      setValue("email", student.student?.email);
      setValue("phone", student.student?.phone);
      setValue("location", student.student?.location);
      setValue("dob", formatDate(student.student?.dob));
      setValue("gender", student.student?.gender);
      setValue("password", "");
      setValue("admissionDate", formatDate(student.student?.admissionDate));
      setValue("cnic", student.student?.cnic);
      setValue("userStatus", student.student?.userStatus);
      setValue("isActive", student.student?.isActive);
      setValue("enrollmentNo", student.student?.enrollmentNo);
      setValue("maritalStatus", student.student?.maritalStatus);
      setValue("profilePicture", student.student?.profilePicture);

      // Parent details
      setValue("userDetails.fatherName", student.userDetails?.fatherName);
      setValue("userDetails.fatherCNIC", student.userDetails?.fatherCNIC);
      setValue("userDetails.fatherContact", student.userDetails?.fatherContact);
      setValue(
        "userDetails.fatherProfession",
        student.userDetails?.fatherProfession
      );
      setValue("userDetails.motherName", student.userDetails?.motherName);
      setValue("userDetails.motherCNIC", student.userDetails?.motherCNIC);
      setValue("userDetails.motherContact", student.userDetails?.motherContact);
      setValue(
        "userDetails.motherProfession",
        student.userDetails?.motherProfession
      );
      setValue("userDetails.parentEmail", student.userDetails?.parentEmail);
      // setValue("bloodGroup", student.userDetails?.bloodGroup);

      // Academic details
      setValue(
        "academicDetails.courseLevel",
        student.academicDetails?.courseLevel
      );
      const classId = student.assignedClasses?.[0]?.classId || "";
      const sectionId =
        student.assignedClasses?.[0]?.classSections?.[0]?.id || "";

      setValue("classId", classId);
      setValue("sectionId", sectionId);

      setValue(
        "academicDetails.firstSemResult",
        student.academicDetails?.firstSemResult
      );
      setValue(
        "academicDetails.secondSemResult",
        student.academicDetails?.secondSemResult
      );
      setValue(
        "academicDetails.thirdSemResult",
        student.academicDetails?.thirdSemResult
      );
      setValue(
        "academicDetails.overallAnnualResult",
        student.academicDetails?.overallAnnualResult
      );

      // Fee and dues
      setValue("feeAndDues.monthlyFee", student.feeAndDues?.monthlyFee);
      setValue(
        "feeAndDues.anySpecialDiscount",
        student.feeAndDues?.anySpecialDiscount
      );
      setValue(
        "feeAndDues.siblingDiscount",
        student.feeAndDues?.siblingDiscount
      );
      setValue(
        "feeAndDues.employeeKidDiscount",
        student.feeAndDues?.employeeKidDiscount
      );
      setValue("feeAndDues.lmsFee", student.feeAndDues?.lmsFee);
      setValue(
        "feeAndDues.extraCurricularActFee",
        student.feeAndDues?.extraCurricularActFee
      );
      // setValue("feeAndDues.totalAmount", student.feeAndDues?.totalAmount);

      setValue("attendance", student.attendance);
      setValue("teachers_observation", student.teachers_observation);

      setSelectedClass(classId); // Update selected class state
      setSections(
        classes
          .find((cls) => cls.id === classId)
          ?.classSections?.map((section) => ({
            id: section.id,
            name: section.sectionName,
          })) || []
      );
      setCurrentStudentId(student.student?.id);
      setCurrentPicture(student.student?.profilePicture);
      setIsEdit(true);
      setIsModalOpen(true);
    }
  };
  const formatDate = (date) => {
    if (!date) return ""; // Handle null or undefined
    const parsedDate = new Date(date);
    return isNaN(parsedDate) ? "" : parsedDate.toISOString().split("T")[0];
  };

  const handleBulkUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch action to upload the bulk file
    // dispatch(addParentBulk(formData))
    //   .then(() => toast.success("Bulk upload successful!"))
    //   .catch(() => toast.error("Bulk upload failed!"));
  };

  const handleModalClose = () => {
    setUploadType(""); // Reset the dropdown value
    setIsEdit(false);
  };

  const handleUploadTypeChange = (type) => {
    setUploadType(type);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteStudent(id)).unwrap();
      toast.success("Student deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete student");
    }
  };

  const handleStudentCardClick = (card, index) => {
    setTileFilter(card.status); //

    setSelectedCard(index);
  };

  const handlePageChange = (newPage) => {
    if (newPage !== pageNumber) {
      dispatch(setPageNumber(newPage)); // Only set the page number in the Redux store
    }
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const uniqueCampuses = Array.from(
    new Set(students?.map((student) => student.student?.location))
  );
  const uniqueStandards = Array.from(
    new Set(classes?.map((cls) => cls.className))
  );

  const uniqueSections = Array.from(
    new Set(
      classes?.flatMap((cls) =>
        cls.classSections?.map((section) => section.sectionName)
      )
    )
  );

  const toggleStudentSelection = (studentId, studentName) => {
    setSelectedStudents((prev) => {
      // Check if the student is already selected
      const isSelected = prev.some((student) => student.id === studentId);
      if (isSelected) {
        // Remove the student if already selected
        return prev.filter((student) => student.id !== studentId);
      } else {
        return [...prev, { id: studentId, name: studentName }];
      }
    });
  };

  const toggleSelectAll = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]); // Deselect all
    } else {
      setSelectedStudents(
        students.map((student) => ({
          id: student.student?.id,
          name: `${student.student?.firstName} ${student.student?.lastName}`,
        }))
      );
    }
  };

  const onSendNotification = (payload) => {
    console.log("Sending notification to:", payload);

    // Call your API here to send the notification
  };
  // Handle notification submission
  const handleSubmitNotification = (payload) => {
    onSendNotification(payload);
    setIsModalOpen(false);
    setSelectedStudents([]);
  };

  const filterStudents = (students = [], query) => {
    if (!query) {
      return students;
    }
    return students?.filter((student) => {
      const usernameMatch = student.student.firstName
        .toLowerCase()
        .includes(query.toLowerCase());
      const idMatch =
        student.id && student.student.id.toString().includes(query);
      return usernameMatch || idMatch;
    });
  };

  const filteredStudents = filterStudents(students, searchQuery);

  const studentsToDisplay = filteredStudents.filter((student) => {
    const matchesCampus = campusFilter
      ? student.student?.location === campusFilter
      : true;

    const matchesStandard = standardFilter
      ? student.academicDetails.class === standardFilter
      : true;

    const matchesSection = sectionFilter
      ? student.academicDetails.section === sectionFilter
      : true;

    var matchesTile = true;
    if (tileFilter === "TP") {
      matchesTile = tileFilter
        ? topPerformers.includes(student.overall_annual_res)
        : true;
    } else if (tileFilter == "EA") {
      matchesTile = tileFilter ? parseFloat(student.attendance) >= 90 : true;
    } else if (tileFilter == "FD") {
      matchesTile = tileFilter ? student.monthly_fee > 500 : true;
    } else if (tileFilter == "ED") {
      matchesTile = tileFilter ? student.emp_kids_disc > 0 : true;
    } else {
      matchesTile = tileFilter ? student : true;
    }
    return matchesCampus && matchesStandard && matchesSection && matchesTile;
  });

  const totalPages = Math.ceil(totalCount / pageSize);
  const currentStudents = studentsToDisplay;

  return (
    <>
      <Toaster />
      <div className="container mx-auto p-4 border border-[#241763]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
          {studentcardData.map((card, index) => (
            <div
              key={index}
              onClick={() => handleStudentCardClick(card, index)}
              className="cursor-pointer"
            >
              <StudentCards
                icon={card.icon}
                title={card.title}
                number={card.number}
                isSelected={selectedCard === index}
                className="truncate cursor-pointer"
              />
            </div>
          ))}
        </div>

        <div className="mb-4 flex gap-4">
          <div className="flex flex-grow items-end lg:col-span-2">
            <input
              type="text"
              placeholder="Search by name or ID"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                dispatch(fetchStudents({ pageNumber: 1, pageSize }));
              }}
              className="w-full p-2 border rounded"
            />
          </div>

          <div>
            <CustomDropdown
              options={uniqueCampuses}
              value={campusFilter}
              onChange={(value) => setCampusFilter(value)}
              placeholder="Location"
            />
          </div>

          <div>
            <CustomDropdown
              options={uniqueStandards}
              value={standardFilter}
              onChange={(value) => setStandardFilter(value)}
              placeholder="All classes"
            />
          </div>

          <div>
            <CustomDropdown
              options={uniqueSections}
              value={sectionFilter}
              onChange={(value) => setSectionFilter(value)}
              placeholder="All sections"
            />
          </div>
          <div className="sm:flex-[1_1_0%]">
            <select
              className="w-full bg-[#241763] text-white p-2 rounded"
              onChange={(e) => handleUploadTypeChange(e.target.value)}
              value={uploadType}
            >
              <option value="" disabled>
                Add student
              </option>
              <option value="single">Add Student</option>
              <option value="bulk">Bulk Upload Student</option>
            </select>
          </div>
          <div
            className="bg-[#241763] p-2 rounded"
            onClick={() => setIsNotificationsModal(true)}
          >
            <SendHorizonal className="text-white w-6 h-6 group-hover:text-white" />
          </div>
        </div>

        <StudentModal
          isModalOpen={isModalOpen}
          isEdit={isEdit}
          onSubmit={onSubmit}
          register={register}
          handleSubmit={handleSubmit}
          setIsModalOpen={setIsModalOpen}
          password={password}
          reset={reset}
          currentPicture={currentPicture}
          classes={classes}
          sections={sections}
          setSections={setSections}
          uploadType={uploadType}
          onModalClose={handleModalClose}
          selectedClass={selectedClass} // Pass selectedClass
          setSelectedClass={setSelectedClass} // Pass setSelectedClass
          fetchFeeStructures={() => feeStructures}
        />

        {isNotificationsModal && (
          <NotificationsModal
            selectedItems={selectedStudents}
            toggleItemSelection={toggleStudentSelection}
            handleSubmitNotification={handleSubmitNotification}
            onClose={() => setIsNotificationsModal(false)}
          />
        )}

        {loading ? (
          <div className="flex justify-center items-center h-40">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-[#241763]"></div>
            <span className="ml-2 text-[#241763]">Loading...</span>
          </div>
        ) : (
          <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
            <table className="table-auto w-full mt-6">
              <thead className="py-4 border-b font-bold">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={
                        selectedStudents.length > 0 &&
                        selectedStudents.length === students.length
                      }
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Email</th>
                  <th>Admission Date</th>
                  <th>Phone</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {currentStudents.map((student, index) => (
                  <>
                    <tr
                      key={student.student?.id}
                      className="p-2 py-2 hover:bg-gray-200 space-y-2"
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedStudents.some(
                            (s) => s.id === student.student?.id
                          )}
                          onChange={() =>
                            toggleStudentSelection(
                              student.student?.id,
                              `${student.student?.firstName} ${student.student?.lastName}`
                            )
                          }
                        />
                      </td>
                      <td>
                        {student.student?.profilePicture ? (
                          <img
                            src={student.student.profilePicture}
                            alt="Student Profile"
                            className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                          />
                        ) : (
                          <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gray-200 flex items-center justify-center">
                            <User className="w-6 h-6 text-gray-500" />
                          </div>
                        )}
                      </td>
                      <td>{`${student.student?.firstName} ${student.student?.lastName}`}</td>
                      <td>{student.student?.gender}</td>
                      <td>{student.student?.email}</td>
                      <td>{student.student?.admissionDate}</td>
                      <td className="text-blue-500">
                        {student.student?.phone}
                      </td>
                      <td className="space-x-2 flex items-center py-2">
                        <button
                          className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                          onClick={() => handleEdit(student)}
                        >
                          <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                        </button>
                        <button
                          className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                          onClick={() => handleDelete(student.student?.id)}
                        >
                          <Trash2 className="text-[#241763]  w-4 h-4 group-hover:text-white" />
                        </button>
                        <div
                          onClick={() => toggleView(index)}
                          className="cursor-pointer  hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                        >
                          {expandedRowIndex === index ? (
                            <ChevronUp
                              size={16}
                              className="group-hover:text-white"
                            />
                          ) : (
                            <ChevronDown
                              size={16}
                              className="group-hover:text-white"
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                    {expandedRowIndex === index && (
                      <tr>
                        <td colSpan="10">
                          <div className="bg-gray-100 p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {/* Personal Details */}
                            <div className="bg-white shadow-md rounded-lg p-4">
                              <h1 className="font-bold text-base text-blue-400 mb-2">
                                Personal Details
                              </h1>
                              <table className="w-full">
                                <tbody>
                                  <tr>
                                    <td className="font-semibold">Location</td>
                                    <td>{student.student?.location}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">Enroll</td>
                                    <td>{student.student?.enrollmentNo}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">DOB</td>
                                    <td>{student.student?.dob}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Marital Status
                                    </td>
                                    <td>{student.student?.maritalStatus}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Stud Status
                                    </td>
                                    <td>{student.student?.userStatus}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">isActive</td>
                                    <td>
                                      {student.student?.isActive ? "Yes" : "No"}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                  <td className="font-semibold">Standard</td>
                                  <td>{student.student?.standard}</td>
                                </tr>
                                <tr>
                                  <td className="font-semibold">Section</td>
                                  <td>{student.student?.section}</td>
                                </tr> */}
                                </tbody>
                              </table>
                            </div>

                            {/* Parent Details */}
                            <div className="bg-white shadow-md rounded-lg p-4">
                              <h1 className="font-bold text-base text-blue-400 mb-2">
                                Parent Details
                              </h1>
                              <table className="w-full">
                                <tbody>
                                  <tr>
                                    <td className="font-semibold">
                                      Father Name
                                    </td>
                                    <td>{student.userDetails?.fatherName}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">CNIC</td>
                                    <td>{student.userDetails?.fatherCNIC}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">Contact</td>
                                    <td>
                                      {student.userDetails?.fatherContact}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Profession
                                    </td>
                                    <td>
                                      {student.userDetails?.fatherProfession}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Mother Name
                                    </td>
                                    <td>{student.userDetails?.motherName}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">CNIC</td>
                                    <td>{student.userDetails?.motherCNIC}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">Contact</td>
                                    <td>
                                      {student.userDetails?.motherContact}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Profession
                                    </td>
                                    <td>
                                      {student.userDetails?.motherProfession}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">Blood</td>
                                    <td>{student.userDetails?.bloodGroup}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Parent Email
                                    </td>
                                    <td>{student.userDetails?.parentEmail}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            {/* Assigned Classes */}
                            <div className="bg-white shadow-md rounded-lg p-4">
                              <h1 className="font-bold text-base text-blue-400 mb-2">
                                Assigned Classes
                              </h1>
                              <table className="w-full border-collapse border border-gray-300">
                                <thead>
                                  <tr>
                                    <th className="border border-gray-300 px-2 py-1">
                                      Class
                                    </th>
                                    <th className="border border-gray-300 px-2 py-1">
                                      Section
                                    </th>
                                    <th className="border border-gray-300 px-2 py-1">
                                      Subjects
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {student.assignedClasses?.map(
                                    (assignedClass, classIndex) =>
                                      assignedClass.classSections?.map(
                                        (section, sectionIndex) => (
                                          <tr
                                            key={`${classIndex}-${sectionIndex}`}
                                          >
                                            <td className="border border-gray-300 px-2 py-1">
                                              {assignedClass.className || "N/A"}
                                            </td>
                                            <td className="border border-gray-300 px-2 py-1">
                                              {section.sectionName || "N/A"}
                                            </td>
                                            <td className="border border-gray-300 px-2 py-1">
                                              {section.subjects
                                                ?.filter(Boolean)
                                                .join(", ") || "N/A"}
                                            </td>
                                          </tr>
                                        )
                                      )
                                  )}
                                </tbody>
                              </table>
                            </div>

                            {/* Academic Performance */}
                            <div className="bg-white shadow-md rounded-lg p-4">
                              <h1 className="font-bold text-base text-blue-400 mb-2">
                                Academic Performance
                              </h1>
                              <table className="w-full">
                                <tbody>
                                  <tr>
                                    <td className="font-semibold">Classes</td>
                                    <td>{student.academicDetails?.class}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Course Level
                                    </td>
                                    <td>
                                      {student.academicDetails?.courseLevel}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">Section</td>
                                    <td>{student.academicDetails?.section}</td>
                                  </tr>
                                  {/* <tr>
                                  <td className="font-semibold">
                                    Assignment Completion
                                  </td>
                                  <td>{student.academicDetails?.assignment_completion}</td>
                                </tr> */}
                                  <tr>
                                    <td className="font-semibold">
                                      1st Term Result
                                    </td>
                                    <td>
                                      {student.academicDetails?.firstSemResult}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      2nd Term Result
                                    </td>
                                    <td>
                                      {student.academicDetails?.secondSemResult}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      3rd Term Result
                                    </td>
                                    <td>
                                      {student.academicDetails?.thirdSemResult}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Overall Annual Result
                                    </td>
                                    <td>
                                      {
                                        student.academicDetails
                                          ?.overallAnnualResult
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            {/* Behavior & Attendance */}
                            <div className="bg-white shadow-md rounded-lg p-4">
                              <h1 className="font-semibold text-base text-blue-400 mb-2 mt-2">
                                Fee & Dues Details
                              </h1>
                              <table className="w-full">
                                <tbody>
                                  <tr>
                                    <td className="font-semibold">
                                      Monthly Fee
                                    </td>
                                    <td>{student.feeAndDues?.monthlyFee}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Any Special Discount
                                    </td>
                                    <td>
                                      {student.feeAndDues?.anySpecialDiscount}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Sibling Discount
                                    </td>
                                    <td>
                                      {student.feeAndDues?.siblingDiscount}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Employee Kids Discount
                                    </td>
                                    <td>
                                      {student.feeAndDues?.employeeKidDiscount}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">LMS Fee</td>
                                    <td>{student.feeAndDues?.lmsFee}</td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      Extra Curricular Fee
                                    </td>
                                    <td>
                                      {
                                        student.feeAndDues
                                          ?.extraCurricularActFee
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-semibold">
                                      total amount
                                    </td>
                                    <td>{student.feeAndDues?.totalAmount}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => handlePageChange(pageNumber - 1)}
            className="px-3 py-1 bg-[#241763] text-white rounded"
            disabled={pageNumber === 1}
          >
            Previous
          </button>

          <div className="flex">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => handlePageChange(i + 1)}
                className={`px-3 py-1 mx-1 border rounded ${
                  pageNumber === i + 1
                    ? "bg-[#241763] text-white"
                    : "bg-gray-200"
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>

          <button
            onClick={() => handlePageChange(pageNumber + 1)}
            className="px-3 py-1 bg-[#241763] text-white rounded"
            disabled={pageNumber === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Students;
