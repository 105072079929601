import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import campusesData from '../Constant/campuses.json';

const initialState = {
  campuses: campusesData,
  loading: false,
  error: null,
};

// Async thunk for fetching campuses (simulating async behavior)
export const fetchCampuses = createAsyncThunk(
    "campuses/fetchCampuses",
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetch('/campuses.json'); // Path relative to public folder
        if (!response.ok) {
          throw new Error('Failed to fetch campuses data');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  

const campusesSlice = createSlice({
  name: "campuses",
  initialState,
  reducers: {
    addCampus: (state, action) => {
      state.campuses.push(action.payload);
    },
    updateCampus: (state, action) => {
      const index = state.campuses.findIndex((campus) => campus.id === action.payload.id);
      if (index !== -1) {
        state.campuses[index] = action.payload;
      }
    },
    deleteCampus: (state, action) => {
      state.campuses = state.campuses.filter((campus) => campus.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampuses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampuses.fulfilled, (state, action) => {
        state.loading = false;
        state.campuses = action.payload;
      })
      .addCase(fetchCampuses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { addCampus, updateCampus, deleteCampus } = campusesSlice.actions;

export default campusesSlice.reducer;
