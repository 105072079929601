import React from "react";
import { X } from "lucide-react";

const AddEmployeeModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  reset,
  currentPicture,
}) => {
  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-11/12 md:w-2/3 lg:w-1/2 h-3/4 overflow-hidden rounded shadow-lg relative">
        {/* Modal Header */}
        <div className="flex justify-between items-center border-b pb-2 px-4">
          <h2 className="text-xl font-semibold">
            {isEdit ? "Edit Employee" : "Add Employee"}
          </h2>
          <button
            className="p-2 hover:bg-gray-200 rounded-full"
            onClick={() => {
              setIsModalOpen(false);
              reset();
            }}
          >
            <X />
          </button>
        </div>

        {/* Modal Content with Scrolling */}
        <div className="overflow-y-auto px-6 py-4 h-[calc(100%-60px)]">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, data.profilePicture?.[0])
            )}
          >
            {/* Profile Picture */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">
                Profile Picture
              </label>
              <input
                type="file"
                accept="image/*"
                {...register("profilePicture")}
                className="block w-full text-sm text-slate-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                          file:bg-violet-50 file:text-violet-700
                            hover:file:bg-violet-100
                          "
              />
              {currentPicture && (
                <img
                  src={currentPicture}
                  alt="Profile"
                  className="mt-2 w-20 h-20 rounded-full"
                />
              )}
            </div>

            {/* First Name & Last Name */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-medium">
                  First Name
                </label>
                <input
                  type="text"
                  {...register("firstName", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter first name"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  Last Name
                </label>
                <input
                  type="text"
                  {...register("lastName", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter last name"
                />
              </div>
            </div>

            {/* Father Name */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">
                Father Name
              </label>
              <input
                type="text"
                {...register("fatherName", { required: true })}
                className="mt-2 w-full border rounded p-2"
                placeholder="Enter father's name"
              />
            </div>

            {/* Gender & Marital Status */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-medium">
                  Gender
                </label>
                <select
                  {...register("gender", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  Marital Status
                </label>
                <select
                  {...register("maritalStatus", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                >
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                </select>
              </div>
            </div>

            {/* Email & Phone */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-medium">Email</label>
                <input
                  type="email"
                  {...register("email", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter email"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">Phone</label>
                <input
                  type="text"
                  {...register("phone", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter phone number"
                />
              </div>
            </div>

            {/* Location & CNIC */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-medium">
                  Location
                </label>
                <input
                  type="text"
                  {...register("location", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter location"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">CNIC</label>
                <input
                  type="text"
                  {...register("cnic", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter CNIC"
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Address</label>
              <input
                type="text"
                {...register("address", { required: true })}
                className="mt-2 w-full border rounded p-2"
                placeholder="Enter address"
              />
            </div>

            {/* Emergency Contact & Relationship */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-medium">
                  Emergency Contact
                </label>
                <input
                  type="text"
                  {...register("emergencyContact", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter emergency contact"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  Contact Relationship
                </label>
                <input
                  type="text"
                  {...register("contactRelationShip", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter relationship"
                />
              </div>
            </div>

            {/* Address & Religion */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-medium">
                  Date of Birth
                </label>
                <input
                  type="date"
                  {...register("dob", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-medium">
                  Religion
                </label>
                <input
                  type="text"
                  {...register("religion", { required: true })}
                  className="mt-2 w-full border rounded p-2"
                  placeholder="Enter religion"
                />
              </div>
            </div>

            {/* Is Active */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">
                Active Status
              </label>
              <input
                type="checkbox"
                {...register("isActive")}
                className="mt-2"
              />
            </div>

            {/* Modal Actions */}
            <div className="flex justify-end gap-4">
              <button
                type="button"
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                onClick={() => {
                  setIsModalOpen(false);
                  reset();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#241763] text-white px-4 py-2 rounded"
              >
                {isEdit ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEmployeeModal;
