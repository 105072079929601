import {
  dashboardSvg,
  coursesSvg,
  classScheduleSvg,
  gradeBookSvg,
  examsSvg,
  communitySvg,
  messagesSvg,
  settingsSvg,
  librarySvg,
  adminSvg,
  lessonPlanSvg,
  classManagementSvg,
  singleUserBoldSvg,
  ExamsSvg,
  gradesSvg,
  performanceSvg,
  feesSvg,
  parentCalenderSvg,
  myClass,
  myCourses,
  myStudent,
  myParent,
  curriculumDesign
  // surverySvg,
  // teachericon,
  // userSvg,
} from "./svgs";
export const studentLinks = (t) => [
  {
    label: t("dashboard"),
    path: "/home",
    icon: dashboardSvg,
  },
  {
    label: t("MyClasses"),
    path: "/my-classes",
    icon: classManagementSvg,
  },
  {
    label: t("courses"),
    path: "/courses",
    icon: coursesSvg,
  },
  {
    label: t("classSchedule"),
    path: "/class-schedule",
    icon: classScheduleSvg,
  },
  {
    label: t("gradeBook"),
    path: "/grade-book",
    icon: gradeBookSvg,
  },
  {
    label: t("exams"),
    path: "/exams",
    icon: examsSvg,
  },
  {
    label: t("community"),
    path: "/community",
    icon: communitySvg,
  },
  {
    label: t("library"),
    path: "/library",
    icon: librarySvg,
  },
  {
    label: t("accountSettings"),
    path: "/account-settings",
    icon: settingsSvg,
  }
];

export const teacherLinks = (t) => [
  {
    label: t("dashboard"),
    path: "/home",
    icon: dashboardSvg,
  },
  {
    label: t("lessonPlan"),
    path: "/lesson-plan",
    icon: lessonPlanSvg,
    childRoute: "/add-lesson-plan",
  },
  {
    label: t("curriculumDesign"),
    path: "/curriculum-design",
    icon: curriculumDesign,
  },
  {
    label: t("classManagement"),
    path: "/class-management",
    icon: classManagementSvg,
    childRoute: "/attendance",
  },
  // {
  //   label: t("personalProfile"),
  //   path: "/personal-profile",
  //   icon: singleUserBoldSvg,
  // },
  {
    label: t("examsManagement"),
    path: "/exams-management",
    icon: ExamsSvg,
  },
  {
    label: t("classSchedule"),
    path: "/class-schedule",
    icon: classScheduleSvg,
  },
  {
    label: t("gradesManagement"),
    path: "/grades-management",
    icon: gradesSvg,
  },
  {
    label: t("community"),
    path: "/community",
    icon: communitySvg,
  },
  {
    label: t("library"),
    path: "/library",
    icon: librarySvg,
  },
  {
    label: t("personalProfile"),
    path: "/account-settings",
    icon: settingsSvg,
  },
];


export const parentLinks = (t) => [
  {
    label: t("dashboard"),
    path: "/home",
    icon: dashboardSvg,
  },
  {
    label: t("exam"),
    path: "/exam",
    icon: ExamsSvg,
  },
  {
    label: t("feesDues"),
    path: "/fees-dues",
    icon: feesSvg,
  },
  {
    label: t("performance"),
    path: "/performance",
    icon: performanceSvg,
  },
  {
    label: t("parentalSurvey"),
    path: "/parent-survey",
    icon: feesSvg,
  },
  {
    label: t("calendar"),
    path: "/calendar",
    icon: parentCalenderSvg,
  },
  {
    label: t("accountSetting"),
    path: "/account-setting",
    icon: settingsSvg,
  },
];


export const adminLinks = (t) => [
  {
    label: t("dashboard"),
    path: "/home",
    icon: dashboardSvg,
  },
  // {
  //   label: t("users"),
  //   path: "/users",
  //   icon: singleUserBoldSvg,
  // },
  {
    label: t("Teachers"),
    path: "/teachers",
    icon: classScheduleSvg,
  },
  {
    label: t("Students"),
    path: "/students",
    icon: myStudent,
  },
  {
    label: t("Parents"),
    path: "/parents",
    icon: myParent,
  },
  {
    label: t("Classes"),
    path: "/classes",
    icon: myClass,
  },
  {
    label: t("Library"),
    path: "/libraries",
    icon: librarySvg,
  },
  {
    label: t("Courses"),
    path: "/courses",
    icon: myCourses,
  },
  {
    label: t("User Management"),
    path: "/management",
    icon: myClass,
  },
  {
    label: t("Fee Management"),
    path: "/feeManagement",
    icon: myClass,
  },
  {
    label: t("Campuses"),
    path: "/campuses",
    icon: myClass,
  },
  {
    label: t("Wellness Suite"),
    path: "/surveys&feedback",
    icon: myClass,
  },
];


export const hrLinks = (t) => [
  {
    label: t("HRMS"),
    path: "/hrms",
    icon: myClass, 
    children: [
      { label: t("Emloyee Details"), path: "/employeeDetails" },
      { label: t("Warning Details"), path: "/warningDetails" },
      { label: t("Leave Details"), path: "/leaveDetails" },
      { label: t("Attendance"), path: "/attendance" },
      { label: t("Reports"), path: "/reports" },
    
    ],
  },
  {
    label: t("Payroll"),
    path: "/payroll",
    icon: myClass, 
  },
  {
    label: t("Recruitment"),
    path: "/recruitment",
    icon: myClass, 
  },
  {
    label: t("HrConfiguration"),
    path: "/hrConfiguration",
    icon: myClass, 
  },
];
