import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import CustomDropdown from "../../Components/customs/dropdowns/CustomDropdown";
import { CalendarClock } from "lucide-react";

const ClassesModal = ({ currentClass, onSubmit}) => { // Accepting currentClass as a prop
  const [formData, setFormData] = useState({
    className: "",
    creditHours: "",
    startedSession: "",
    endSession: "",
    sections: [],
    classType: "Online",
    classSubjects: [],
  });

  const [mainInputs, setMainInputs] = useState([]);
  const [subjectChips, setSubjectChips] = useState([]);
  const [sectionChips, setSectionChips] = useState([]);

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Effect to set default values for the form when editing
  useEffect(() => {
    if (currentClass) {
      const sections = currentClass.sections || [];
      setFormData({
        className: currentClass.className,
        creditHours: currentClass.creditHours || 0,
        startedSession: currentClass.startedSession 
          ? new Date(currentClass.startedSession).toISOString().split("T")[0]
          : "",
        endSession: currentClass.endSession 
          ? new Date(currentClass.endSession).toISOString().split("T")[0]
          : "",
        sections: sections,
        classType: currentClass.classType || "Online",
        classSubjects: currentClass.subjects || [],
      });
  
      setMainInputs(
        currentClass.subjects?.map((subject) => ({
          subject: subject.name,
          days: subject.days.map((day) => ({
            day: day.day,
            section: day.section,
            start_time: day.startTime,
            end_time: day.endTime,
          })),
          options: subject.options || [],
        })) || []
      );
  
      setSectionChips(sections);
    }
  }, [currentClass]);

  useEffect(() => {
    if (!currentClass) {
      setFormData({
        className: "",
        creditHours: "",
        startedSession: "",
        endSession: "",
        sections: [],
        classType: "Online",
        classSubjects: [],
      });
      setMainInputs([]);
      setSectionChips([]);
    }
  }, [currentClass]);

  const removeChip = (chipToRemove, chipType) => {
    if (chipType === "subject") {
      setSubjectChips(subjectChips.filter((chip) => chip !== chipToRemove));
    }
    if (chipType === "sections") {
      setSectionChips(sectionChips.filter((chip) => chip !== chipToRemove));
    }
  };

  const removeLastChip = (chipType) => {
    if (chipType === "subject") setSubjectChips(subjectChips.slice(0, -1));
    if (chipType === "sections") setSectionChips(sectionChips.slice(0, -1));
  };

  const addChip = (chip, chipType) => {
    if (chipType === "subject") setSubjectChips([...subjectChips, chip]);
    if (chipType === "sections") setSectionChips([...sectionChips, chip]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { className, classType, creditHours, startedSession, endSession } = formData;

    const formattedSubjects = mainInputs.map((input) => ({
      name: input.subject,
      days: input.days.map((day) => ({
        day: day.day,
        section: day.section,
        startTime: day.start_time,
        endTime: day.end_time,
      }))
    }));

    const payload = {
      className,
      creditHours: creditHours ? parseInt(creditHours, 10) : null,
      startedSession: new Date(startedSession).toISOString(),
      endSession: new Date(endSession).toISOString(),
      sections: sectionChips,
      classType,
      classSubjects: formattedSubjects,
    };

    try {
      await onSubmit(payload);
      setFormData({
          className: "",
          creditHours: "",
          startedSession: "",
          endSession: "",
          section: [],
          class_type: "Online",
          classSubjects: [],
      });
      
      setMainInputs([]);
      setSectionChips([]);
      setSubjectChips([]);
    } catch (err) {
      toast.error("Something went wrong, please try again.");
    }
  };

  const handleAddInputs = () => {
    const newInputs = {
      subject: "",
      days: [{ day: "Monday", start_time: "", end_time: "" }],
      options: [],
    };
    setMainInputs([...mainInputs, newInputs]);
  };

  const addMoreDays = (index) => {
    const newDays = { day: "Monday", start_time: "", end_time: "" };
    const newItems = [...mainInputs];
    newItems[index].days.push(newDays);
    setMainInputs(newItems);
  };

  const handleChangeMainInputs = (value, name, index, dayIndex = null) => {
    const oldInputs = [...mainInputs];
    if (dayIndex === null) {
      oldInputs[index][name] = value; // Update subject name or options
    } else {
      if (name === "start_time" || name === "end_time") {
        // Enforce time format (hh:mm:ss)
        const formattedValue = value.length === 5 ? `${value}:00` : value;
        oldInputs[index].days[dayIndex][name] = formattedValue;
      } else {
        oldInputs[index].days[dayIndex][name] = value;
      }
    }
    setMainInputs(oldInputs);
  };

  const handleRemoveSubject = (index) => {
    const newSubjects = [...mainInputs];
    newSubjects.splice(index, 1);
    setMainInputs(newSubjects);
  };

  const handleRemoveDay = (subjectIndex, dayIndex) => {
    const newSubjects = [...mainInputs];
    newSubjects[subjectIndex].days.splice(dayIndex, 1);
    setMainInputs(newSubjects);
  };

  return (
    <>
      <Toaster />
      <div className="container-fluid   ">
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center py-2 fw-bold fs-6">
            Class Information
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="class">Class/Standard</label>
              <input
                type="text"
                className="form-control"
                placeholder="Class"
                name="className"
                onChange={handleInputChange}
                value={formData.className}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="class">Class type</label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="classType"
                onChange={handleInputChange}
                value={formData.classType}
              >
                <option>Online</option>
                <option>Physical</option>
                <option>Both</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="class">Sections</label>
              <CustomDropdown
                placeholder="to add sections"
                chips={sectionChips}
                addChip={addChip}
                removeLastChip={removeLastChip}
                setChips={setSectionChips}
                removeChip={removeChip}
                chipType="sections"
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="class">Credit Hours</label>
              <input
                type="number"
                className="form-control"
                placeholder="Credit hour"
                name="creditHours"
                onChange={handleInputChange}
                value={formData.creditHours}
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="text-capitalize">
                Start Session
              </label>
              <input
                type="date"
                name="startedSession"
                className="form-control"
                onChange={handleInputChange}
                value={formData.startedSession}
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group">
              <label htmlFor="" className="text-capitalize">
                End Session
              </label>
              <input
                type="date"
                name="endSession"
                className="form-control"
                value={formData.endSession}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-group mt-4">
              <div className="d-flex justify-content-between">
                <label>Add subject per week</label>
                <button
                  htmlFor="class"
                  className="btn btn-secondary mb-3"
                  onClick={handleAddInputs}
                >
                  Add Subjects
                </button>
              </div>
              <div className="d-flex flex-column gap-2">
                {mainInputs?.map((inputs, index) => (
                  <div key={index}>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="subject name"
                        name="subject"
                        value={inputs.subject}
                        onChange={(e) =>
                          handleChangeMainInputs(
                            e.target.value,
                            e.target.name,
                            index
                          )
                        }
                      />
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleRemoveSubject(index)}
                      >
                        &#x2715;
                      </button>
                      <CalendarClock
                        height={40}
                        width={40}
                        onClick={() => addMoreDays(index)}
                        className="cursor-pointer"
                      />
                    </div>

                    {inputs.days?.map((day, dayIndex) => (
                      <div
                        className="d-flex align-items-center gap-4 px-4 mt-3"
                        key={dayIndex}
                      >
                        <select
                          className="form-select"
                          name="day"
                          value={day.day}
                          onChange={(e) =>
                            handleChangeMainInputs(
                              e.target.value,
                              e.target.name,
                              index,
                              dayIndex
                            )
                          }
                        >
                          {days?.map((day, i) => (
                            <option value={day} key={i}>
                              {day}
                            </option>
                          ))}
                        </select>

                        <select
                          className="form-select"
                          name="section"
                          value={day.section || ""}
                          onChange={(e) =>
                            handleChangeMainInputs(
                              e.target.value,
                              e.target.name,
                              index,
                              dayIndex
                            )
                          }
                        >
                          <option value="">Select Section</option>
                          {sectionChips?.map((section, i) => (
                            <option value={section} key={i}>
                              {section}
                            </option>
                          ))}
                        </select>

                        <input
                          type="time"
                          className="form-control"
                          name="start_time"
                          value={day.start_time}
                          onChange={(e) =>
                            handleChangeMainInputs(
                              e.target.value,
                              e.target.name,
                              index,
                              dayIndex
                            )
                          }
                        />
                        <label>to</label>
                        <input
                          type="time"
                          className="form-control"
                          name="end_time"
                          value={day.end_time}
                          onChange={(e) =>
                            handleChangeMainInputs(
                              e.target.value,
                              e.target.name,
                              index,
                              dayIndex
                            )
                          }
                        />
                        <button
                          className="btn btn-danger btn-sm ms-2"
                          onClick={() => handleRemoveDay(index, dayIndex)}
                        >
                          &#x2715;
                        </button>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className=" mt-3 py-4 flex justify-end">
            <div className="form-group mt-4">
              <button
                className="btn bg-main text-white btn-primary"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}; 

export default ClassesModal;
