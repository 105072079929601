import axios from 'axios';

const BASE_URL = "https://lms-api.wiserbee.ca";

const getToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.token;
}

export const getRequest = async (endpoint) => {
  const authToken = getToken();

  try {
    const response = await axios.get(`${BASE_URL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error during GET request:', error);
    throw error;
  }
};

export const deleteRequest = async (endpoint) => {
  const authToken = getToken();

  try {
    const response = await axios.delete(`${BASE_URL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error during GET request:', error);
    throw error;
  }
};

export const postRequest = async (endpoint, data, isMultipart=false) => {
  const authToken = getToken();

  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json ',
    };

    if (isMultipart) {
      headers['Content-Type'] = 'multipart/form-data';
    }

    const response = await axios.post(`${BASE_URL}${endpoint}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Error during POST request:', error?.response?.data);
    throw error?.response?.data;
  }
};

export const updateRequest = async (endpoint, data, isMultipart=false) => {
  const authToken = getToken();

  try {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json ',
    };

    if (isMultipart) {
      headers['Content-Type'] = 'multipart/form-data';
    }
    const response = await axios.put(`${BASE_URL}${endpoint}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Error during UPDATE request:', error);
    throw error;
  }
};
