import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

const TeacherModal = ({
  isModalOpen,
  isEdit,
  onSubmit,
  handleSubmit,
  setIsModalOpen,
  register,
  password,
  currentPicture, // Pass the current picture when editing
  classes,
  selectedClasses,
  uploadType,
  reset,
  onModalClose,
}) => {
  const {
    setError,
    formState: { errors },
  } = useForm();
  const [newPicture, setNewPicture] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // Store the file
  const [selectedSection, setSelectedSection] = useState(null);
  const [filteredSections, setFilteredSections] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [addedClasses, setAddedClasses] = useState([]);
  const [bulkFile, setBulkFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isEdit && Array.isArray(selectedClasses)) {
      // Check if selectedClasses is an array
      console.log("Selected classes:", selectedClasses);

      const mappedClasses = selectedClasses.map((classItem) => {
        // Find the sections for the current class
        const classSections =
          classes?.find((cls) => cls.id === classItem.classId)?.classSections ||
          [];

        return {
          classId: classItem.classId,
          classDetails: classItem.subjects?.map((subject) => {
            // Find the selected section for the subject
            const selectedSection = classSections.find(
              (section) => section.id === subject.sectionId
            );

            // Extract the subjects for the selected section
            const filteredSubjects = selectedSection?.subjects || [];

            console.log("Selected Sections:", selectedSection);
            console.log("Filtered Subjects:", filteredSubjects);

            return {
              sectionId: subject.sectionId,
              subjectId: subject.subjectId,
              filteredSubjects, // Correctly associate subjects for the section
            };
          }),
          filteredSections: classSections, // Include filtered sections
        };
      });

      setAddedClasses(mappedClasses); // Set the mapped classes to the state
    } else {
      setAddedClasses([]);
    }
  }, [isModalOpen, isEdit, selectedClasses, classes]);

  // Update sections when a class is selected
  useEffect(() => {
    setFilteredSections([]);
    setSelectedSection("");
    setFilteredSubjects([]);
  }, [classes]);

  useEffect(() => {
    if (selectedSection) {
      const selectedSectionData = filteredSections.find(
        (section) => section.id === selectedSection
      );

      if (selectedSectionData) {
        const subjects = selectedSectionData.subjects || [];
        setFilteredSubjects(subjects); // Set the subjects for the selected section
      }
    } else {
      setFilteredSubjects([]); // Clear subjects when no section is selected
    }
  }, [selectedSection, filteredSections]);

  useEffect(() => {
    if (isEdit) {
      setSelectedFile(null); // Clear file on edit to avoid conflicts
      setNewPicture(currentPicture);
    }
  }, [isModalOpen, isEdit, currentPicture]);

  const handlePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file); // Store the file
      const imageUrl = URL.createObjectURL(file); // Preview the image
      setNewPicture(imageUrl);
    }
  };

  const handleFormSubmit = (data) => {
    if (!isEdit && data.password !== data.confirmPassword) {
      return setError("confirmPassword", {
        type: "manual",
        message: "Password doesn't match",
      });
    }
    onSubmit({ ...data, addedClasses }, selectedFile); // Include dynamic classes in the submission
  };

  // Add a new class assignment
  const handleAddClass = () => {
    setAddedClasses((prev) => [
      ...prev,
      {
        classId: "",
        classDetails: [],
        filteredSections: [],
        filteredSubjects: [],
      },
    ]);
  };

  // Remove a class assignment
  const handleRemoveClass = (classIndex) => {
    setAddedClasses((prev) => prev.filter((_, idx) => idx !== classIndex));
  };

  // Handle dynamic changes in the selected class
  const handleDynamicClassChange = (classIndex, value) => {
    setAddedClasses((prev) =>
      prev.map((cls, idx) => {
        if (idx === classIndex) {
          const selectedClass = classes.find(
            (classItem) => classItem.id === value
          );
          return {
            ...cls,
            classId: value,
            filteredSections: selectedClass?.classSections || [],
            classDetails: [], // Reset sections and subjects
          };
        }
        return cls;
      })
    );
  };

  // Add a section and subject under a class
  const handleAddSectionSubject = (classIndex) => {
    setAddedClasses((prev) =>
      prev.map((cls, idx) =>
        idx === classIndex
          ? {
              ...cls,
              classDetails: [
                ...cls.classDetails,
                { sectionId: "", subjectId: "", filteredSubjects: [] },
              ],
            }
          : cls
      )
    );
  };

  // Remove a section and subject under a class
  const handleRemoveSectionSubject = (classIndex, sectionIndex) => {
    setAddedClasses((prev) =>
      prev.map((cls, idx) =>
        idx === classIndex
          ? {
              ...cls,
              classDetails: cls.classDetails.filter(
                (_, secIdx) => secIdx !== sectionIndex
              ),
            }
          : cls
      )
    );
  };

  // Handle dynamic changes for section and subject
  const handleDynamicSectionChange = (classIndex, sectionIndex, value) => {
    setAddedClasses((prev) =>
      prev.map((cls, idx) => {
        if (idx === classIndex) {
          const selectedSection = cls.filteredSections.find(
            (sec) => sec.id === value
          );
          const updatedClassDetails = cls.classDetails.map((detail, idx) =>
            idx === sectionIndex
              ? {
                  ...detail,
                  sectionId: value,
                  filteredSubjects: selectedSection?.subjects || [],
                  subjectId: "", // Reset subject when section changes
                }
              : detail
          );
          return { ...cls, classDetails: updatedClassDetails };
        }
        return cls;
      })
    );
  };

  const handleBulkFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBulkFile(file);
    }
  };

  const handleBulkSubmit = async () => {
    if (!bulkFile) {
      toast.error("Please upload a file first!");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const uploadSimulation = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadSimulation);
          setIsUploading(false);
          toast.success("File has been uploaded!");
          setBulkFile(null);
          setIsModalOpen(false);
          onModalClose();
        }
        return prev + 10; // Increment progress
      });
    }, 300); // Adjust interval speed as needed
  };

  const handleClose = () => {
    reset();
    setIsModalOpen(false);
    setUploadProgress(0);
    setBulkFile(null);
    setIsUploading(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleClose}
            className="px-2 py-1 bg-red-500 text-white rounded"
          >
            <span>
              <X />
            </span>
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4">
          {isEdit ? "Edit Teacher" : "Add Teacher"}
        </h2>

        {/* If in edit mode and there's a current picture, display it */}
        {isEdit && currentPicture && (
          <div className="mb-4">
            <label className="block font-semibold mb-1">Current Picture:</label>
            <img
              src={newPicture || currentPicture} // Use new picture if available
              alt="Teacher Profile"
              className="w-20 h-20 rounded-full mb-4"
            />
          </div>
        )}
        {isEdit || uploadType === "single" ? (
          <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
            <h1 className="text-lg font-bold">Personal Details</h1>
            <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
              <div>
                <label className="block">First Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("firstName")}
                  required
                />
              </div>
              <div>
                <label className="block">Last Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("lastName")}
                  required
                />
              </div>
              <div>
                <label className="block">Gender</label>
                <select
                  className="w-full p-2 border rounded"
                  {...register("gender")}
                  required
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div>
                <label className="block">Phone</label>
                <input
                  type="tel"
                  className="w-full p-2 border rounded"
                  {...register("phone")}
                  required
                />
              </div>
              <div>
                <label className="block">Location</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("location")}
                  required
                />
              </div>
              <div>
                <label className="block">Email</label>
                <input
                  type="email"
                  className="w-full p-2 border rounded"
                  {...register("email")}
                  required
                />
              </div>
              {/* Password */}
              <div>
                <label className="block">Password</label>
                <input
                  type="password"
                  {...register("password", {
                    required: !isEdit && "Password is required", // Required if not edit mode
                  })}
                  placeholder="Password"
                  className="w-full p-2 border rounded"
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">
                    {errors.password.message}
                  </p>
                )}
              </div>

              {/* Confirm Password */}
              <div>
                <label className="block">Confirm Password</label>
                <input
                  type="password"
                  {...register("confirmPassword", {
                    validate: (value) =>
                      isEdit || value === password || "Passwords do not match",
                  })}
                  placeholder="Confirm Password"
                  className="w-full p-2 border rounded"
                />
                {errors.confirmPassword && (
                  <p className="text-red-500 text-sm">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
              <div>
                <label className="block">DOB</label>
                <input
                  type="date"
                  className="w-full p-2 border rounded"
                  {...register("dob")}
                  required
                />
              </div>
              <div>
                <label className="block">Joining Date</label>
                <input
                  type="date"
                  className="w-full p-2 border rounded"
                  {...register("joiningDate")}
                  required
                />
              </div>

              <div>
                <label className="block">CNIC</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("cnic")}
                  required
                />
              </div>
              <div>
                <label className="block">Employee Code</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("employeeCode")}
                  required={isEdit}
                  readOnly={!isEdit}
                />
              </div>
              {/* New fields */}
              {isEdit && (
                <div>
                  <label className="block">Status</label>
                  <select
                    className="w-full p-2 border rounded"
                    {...register("userStatus")}
                    required
                  >
                    <option value="Active">Active</option>
                    <option value="Resigned">Resigned</option>
                    <option value="Unpaid Leaves">Unpaid Leaves</option>
                    <option value="Promoted">Promoted</option>
                  </select>
                </div>
              )}
              <div>
                <label className="block">Marital Status</label>
                <select
                  className="w-full p-2 border rounded"
                  {...register("maritalStatus")}
                  required
                >
                  <option value="Single">Single</option>
                  <option value="Marreid">Married</option>
                </select>
              </div>

              <div>
                <label className="block">
                  {isEdit ? "Upload New Picture" : "Upload Picture"}
                </label>
                <input
                  type="file"
                  className="w-full p-2 border rounded"
                  onChange={handlePictureChange}
                  accept="image/*"
                  required={!isEdit}
                />
              </div>
            </div>
            <h1 className="mt-2 text-lg font-bold">
              Courses & Classes Academic Performance
            </h1>
            <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
              <div>
                <label className="block">Course Level</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("courseLevel")}
                  readOnly
                />
              </div>

              <div>
                <label className="block">1st Sems Result</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("firstSemResult")}
                  readOnly
                />
              </div>
              <div>
                <label className="block">2nd Sems Result</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("secondSemResult")}
                  readOnly
                />
              </div>
              <div>
                <label className="block">3rd Sems Result</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("thirdSemResult")}
                  readOnly
                />
              </div>
              <div>
                <label className="block">Overall Annual Result</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("overallAnnualResult")}
                  readOnly
                />
              </div>
            </div>
            <h1 className="mt-2 text-lg font-bold">Academic & Experience</h1>
            <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
              <div>
                <label className="block">Institute</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("academicInstitute")}
                  required
                />
              </div>
              <div>
                <label className="block">Completed In</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  {...register("completedYear")}
                  required
                />
              </div>
              <div>
                <label className="block">Major Subject</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("majorSubject")}
                  required
                />
              </div>
              <div>
                <label className="block">CGPA</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  {...register("cgpa")}
                  required
                />
              </div>
              <div>
                <label className="block">Percentage</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  {...register("percentage")}
                  required
                />
              </div>
            </div>
            <h1 className="mt-2 text-lg font-bold">Professional Experience</h1>
            <div className="grid grid-cols-2 gap-4 border-b border-gray-200 pb-4">
              <div>
                <label className="block">Organization Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("organizationName")}
                />
              </div>
              <div>
                <label className="block">Designation</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("designation")}
                />
              </div>
              <div>
                <label className="block">Started date</label>
                <input
                  type="date"
                  className="w-full p-2 border rounded"
                  {...register("startedOn")}
                />
              </div>
              <div>
                <label className="block">Ended Date</label>
                <input
                  type="date"
                  className="w-full p-2 border rounded"
                  {...register("endedOn")}
                />
              </div>
              <div>
                <label className="block">Last Salary Drawn</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  {...register("lastSalaryDrawn")}
                />
              </div>
              <div>
                <label className="block">Main Responsiblities</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("mainResponsibilities")}
                />
              </div>
              <div>
                <label className="block">Other</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  {...register("other")}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <span className="text-base font-semibold">
                  Add Classes for Teacher
                </span>
              </div>
              <button
                type="button"
                onClick={handleAddClass}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                <span>+</span> Add Class
              </button>
            </div>

            {addedClasses.map((cls, classIndex) => (
              <div
                key={classIndex}
                className="mt-4 border-t border-gray-200 pt-4"
              >
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <label>Class</label>
                    <select
                      value={cls.classId}
                      onChange={(e) =>
                        handleDynamicClassChange(classIndex, e.target.value)
                      }
                      className="w-full p-2 border rounded"
                    >
                      <option value="">Select a Class</option>
                      {classes.map((classItem) => (
                        <option key={classItem.id} value={classItem.id}>
                          {classItem.className}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleRemoveClass(classIndex)}
                    className="text-red-500 text-3xl font-bold"
                  >
                    ×
                  </button>
                </div>

                {cls.classDetails.map((section, sectionIndex) => (
                  <div
                    key={sectionIndex}
                    className="mt-4 ml-8 flex items-center gap-4"
                  >
                    <div className="flex-1">
                      <label>Section</label>
                      <select
                        value={section.sectionId}
                        onChange={(e) =>
                          handleDynamicSectionChange(
                            classIndex,
                            sectionIndex,
                            e.target.value
                          )
                        }
                        className="w-full p-2 border rounded"
                      >
                        <option value="">Select a Section</option>
                        {cls.filteredSections.map((sec) => (
                          <option key={sec.id} value={sec.id}>
                            {sec.sectionName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex-1">
                      <label>Subject</label>
                      <select
                        value={section.subjectId}
                        onChange={(e) =>
                          setAddedClasses((prev) =>
                            prev.map((cls, idx) =>
                              idx === classIndex
                                ? {
                                    ...cls,
                                    classDetails: cls.classDetails.map(
                                      (detail, sIdx) =>
                                        sIdx === sectionIndex
                                          ? {
                                              ...detail,
                                              subjectId: e.target.value,
                                            }
                                          : detail
                                    ),
                                  }
                                : cls
                            )
                          )
                        }
                        className="w-full p-2 border rounded"
                      >
                        <option value="">Select a Subject</option>
                        {section.filteredSubjects
                          ?.filter(
                            (subj) =>
                              !cls.classDetails.some(
                                (detail) =>
                                  detail.subjectId === subj.id &&
                                  detail !== section
                              )
                          )
                          .map((subj) => (
                            <option key={subj.id} value={subj.id}>
                              {subj.subjectName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <button
                      type="button"
                      onClick={() =>
                        handleRemoveSectionSubject(classIndex, sectionIndex)
                      }
                      className="text-red-500 text-3xl font-bold"
                    >
                      ×
                    </button>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={() => handleAddSectionSubject(classIndex)}
                  className="mt-4 text-blue-500 font-bold"
                  disabled={!cls.classId}
                >
                  + Add Section and Subject
                </button>
              </div>
            ))}

            <div className="flex justify-end space-x-4 pt-24">
              <button
                type="submit"
                className="px-4 py-2 bg-[#241763] text-white rounded"
              >
                {isEdit ? "Update" : "Add"}
              </button>
            </div>
          </form>
        ) : (
          <div className="space-y-4">
            <h1 className="text-lg font-bold">Bulk Upload</h1>
            <input
              type="file"
              className="w-full p-2 border rounded"
              onChange={handleBulkFileChange}
              accept=".xlsx, .xls"
              disabled={isUploading}
            />
            {isUploading && (
              <div className="mt-4">
                <label className="block font-medium mb-2">
                  Upload Progress
                </label>
                <div className="relative w-full h-4 bg-gray-200 rounded">
                  <div
                    className="absolute top-0 left-0 h-4 bg-green-500 rounded"
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
                <span className="text-sm text-gray-600">{uploadProgress}%</span>
              </div>
            )}
            <div className="flex justify-end mt-4">
              <button
                type="button"
                onClick={handleBulkSubmit}
                className={`px-4 py-2 rounded ${
                  isUploading
                    ? "bg-gray-400 text-white cursor-not-allowed"
                    : "bg-[#241763] text-white"
                }`}
                disabled={isUploading}
              >
                {isUploading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherModal;
