import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchManagement = createAsyncThunk(
  "management/fetchManagement",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch("/management.json"); // Path relative to the public folder
      if (!response.ok) {
        throw new Error("Failed to fetch management data");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const managementSlice = createSlice({
  name: "management",
  initialState: {
    management: [],
    loading: false,
    error: null,
  },
  reducers: {
    addManagement: (state, action) => {
      state.management.push(action.payload);
    },
    updateManagement: (state, action) => {
      const index = state.management.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.management[index] = action.payload;
      }
    },
    deleteManagement: (state, action) => {
      state.management = state.management.filter((item) => item.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchManagement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchManagement.fulfilled, (state, action) => {
        state.loading = false;
        state.management = action.payload;
      })
      .addCase(fetchManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { addManagement, updateManagement, deleteManagement } = managementSlice.actions;
export default managementSlice.reducer;
