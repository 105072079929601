import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Plus, X } from "lucide-react";

const AutomationModal = () => {
  const [formData, setFormData] = useState({
    // School Details
    schoolName: "",
    academicYear: "",
    startDate: "",
    endDate: "",
    workingDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    },
    dailySchedule: {
      startTime: "",
      endTime: ""
    },

    // Teachers
    teachers: [{
      name: "",
      subjects: [],
      maxWeeklyHours: "",
      availability: {
        monday: { available: false, slots: [] },
        tuesday: { available: false, slots: [] },
        wednesday: { available: false, slots: [] },
        thursday: { available: false, slots: [] },
        friday: { available: false, slots: [] }
      },
      preferredSlots: []
    }],

    // Subject Details
    subjects: [{
      name: "",
      weeklyHours: "",
      preferredTimeSlot: "",
      teacherAssigned: "",
      facilityRequirements: "",
    }],

    // Room/Facility Details 
    rooms: [{
      name: "",
      capacity: "",
      type: "", // Classroom, Lab, Auditorium
      equipment: [],
    }],

    // Constraints
    constraints: {
      breakTimes: {
        monday: [{ start: "", end: "", available: false, slots: [] }],
        tuesday: [{ start: "", end: "", available: false, slots: [] }],
        wednesday: [{ start: "", end: "", available: false, slots: [] }],
        thursday: [{ start: "", end: "", available: false, slots: [] }],
        friday: [{ start: "", end: "", available: false, slots: [] }]
      },
      gapBetweenClasses: "",
      maxConsecutiveHoursTeacher: "",
      maxConsecutiveHoursStudent: "",
      teacherPreferences: [{
        teacherId: "",
        preferredRooms: [],
        preferredTimeSlots: []
      }],
      roomPreferences: [{
        roomId: "",
        preferredSubjects: [],
        preferredTeachers: []
      }],
      specialEvents: [{
        name: "",
        date: "",
        startTime: "",
        endTime: "",
        room: "",
        teacher: "",
        description: ""
      }]
    },

    // General Settings
    settings: {
      timetableFormat: "weekly", // weekly, daily
      outputViews: {
        perClass: true,
        perTeacher: true,
        perRoom: true
      },
      language: "english"
    },

    // Classroom Details
    classrooms: [{
      gradeName: "",
      studentCount: "",
      subjectGroups: [{
        subject: "",
        numberOfGroups: "",
        studentsPerGroup: ""
      }],
      specialRequirements: ""
    }]
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleWorkingDayToggle = (day) => {
    setFormData(prev => ({
      ...prev,
      workingDays: {
        ...prev.workingDays,
        [day]: !prev.workingDays[day]
      }
    }));
  };

  const addTeacher = () => {
    setFormData(prev => ({
      ...prev,
      teachers: [...prev.teachers, {
        name: "",
        subjects: [],
        maxWeeklyHours: "",
        availability: {
          monday: { available: false, slots: [] },
          tuesday: { available: false, slots: [] },
          wednesday: { available: false, slots: [] },
          thursday: { available: false, slots: [] },
          friday: { available: false, slots: [] }
        },
        preferredSlots: []
      }]
    }));
  };

  const removeTeacher = (index) => {
    setFormData(prev => ({
      ...prev,
      teachers: prev.teachers.filter((_, i) => i !== index)
    }));
  };

  const addSubject = () => {
    setFormData(prev => ({
      ...prev,
      subjects: [...prev.subjects, {
        name: "",
        weeklyHours: "",
        preferredTimeSlot: "",
        teacherAssigned: "",
        facilityRequirements: "",
      }]
    }));
  };

  const removeSubject = (index) => {
    setFormData(prev => ({
      ...prev,
      subjects: prev.subjects.filter((_, i) => i !== index)
    }));
  };

  const addRoom = () => {
    setFormData(prev => ({
      ...prev,
      rooms: [...prev.rooms, {
        name: "",
        capacity: "",
        type: "",
        equipment: []
      }]
    }));
  };

  const removeRoom = (index) => {
    setFormData(prev => ({
      ...prev,
      rooms: prev.rooms.filter((_, i) => i !== index)
    }));
  };

  const addClassroom = () => {
    setFormData(prev => ({
      ...prev,
      classrooms: [...prev.classrooms, {
        gradeName: "",
        studentCount: "",
        subjectGroups: [{
          subject: "",
          numberOfGroups: "",
          studentsPerGroup: ""
        }],
        specialRequirements: ""
      }]
    }));
  };

  const removeClassroom = (index) => {
    setFormData(prev => ({
      ...prev,
      classrooms: prev.classrooms.filter((_, i) => i !== index)
    }));
  };

  const addSubjectGroup = (classroomIndex) => {
    const newClassrooms = [...formData.classrooms];
    newClassrooms[classroomIndex].subjectGroups.push({
      subject: "",
      numberOfGroups: "",
      studentsPerGroup: ""
    });
    setFormData(prev => ({ ...prev, classrooms: newClassrooms }));
  };

  const removeSubjectGroup = (classroomIndex, groupIndex) => {
    const newClassrooms = [...formData.classrooms];
    newClassrooms[classroomIndex].subjectGroups = newClassrooms[classroomIndex].subjectGroups.filter((_, i) => i !== groupIndex);
    setFormData(prev => ({ ...prev, classrooms: newClassrooms }));
  };

  const handleSubjectAdd = (teacherIndex, subject) => {
    const newTeachers = [...formData.teachers];
    newTeachers[teacherIndex].subjects.push(subject);
    setFormData(prev => ({ ...prev, teachers: newTeachers }));
  };

  const handleSubjectRemove = (teacherIndex, subjectToRemove) => {
    const newTeachers = [...formData.teachers];
    newTeachers[teacherIndex].subjects = newTeachers[teacherIndex].subjects.filter(
      subject => subject !== subjectToRemove
    );
    setFormData(prev => ({ ...prev, teachers: newTeachers }));
  };

  const handleAvailabilityChange = (teacherIndex, day, available) => {
    const newTeachers = [...formData.teachers];
    newTeachers[teacherIndex].availability[day].available = available;
    setFormData(prev => ({ ...prev, teachers: newTeachers }));
  };

  const handleTimeSlotAdd = (teacherIndex, day, slot) => {
    const newTeachers = [...formData.teachers];
    newTeachers[teacherIndex].availability[day].slots.push(slot);
    setFormData(prev => ({ ...prev, teachers: newTeachers }));
  };

  return (
    <>
      <Toaster />
      <div className="container-fluid">
       
        {/* School/Institute Details Section */}
        <div className="row mb-4">
          <div className="col-12">
            <h3 className="text-lg font-semibold mb-3">School/Institute Details</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label>School Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="schoolName"
                  value={formData.schoolName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Academic Year/Term</label>
                <input
                  type="text"
                  className="form-control"
                  name="academicYear"
                  value={formData.academicYear}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mt-3">
              <label>Working Days</label>
              <div className="flex gap-2">
                {Object.keys(formData.workingDays).map(day => (
                  <button
                    key={day}
                    className={`px-3 py-1 rounded ${formData.workingDays[day] ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    onClick={() => handleWorkingDayToggle(day)}
                  >
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Teacher Details Section */}
        <div className="row mb-4">
          <div className="col-12">
            <div className="flex justify-between items-center mb-3">
              <h3 className="text-lg font-semibold">Teacher Details</h3>
              <button
                className="btn btn-primary btn-sm"
                onClick={addTeacher}
              >
                <Plus className="h-4 w-4 mr-1" />
                Add Teacher
              </button>
            </div>

            {formData.teachers.map((teacher, index) => (
              <div key={index} className="border p-3 mb-3 rounded">
                <div className="flex justify-end">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeTeacher(index)}
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="form-group">
                    <label>Teacher Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={teacher.name}
                      onChange={(e) => {
                        const newTeachers = [...formData.teachers];
                        newTeachers[index].name = e.target.value;
                        setFormData(prev => ({ ...prev, teachers: newTeachers }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Maximum Weekly Hours</label>
                    <input
                      type="number"
                      className="form-control"
                      value={teacher.maxWeeklyHours}
                      onChange={(e) => {
                        const newTeachers = [...formData.teachers];
                        newTeachers[index].maxWeeklyHours = e.target.value;
                        setFormData(prev => ({ ...prev, teachers: newTeachers }));
                      }}
                    />
                  </div>

                  {/* Subject Tags Input */}
                  <div className="form-group col-span-2">
                    <label>Subjects</label>
                    <div className="flex flex-wrap gap-2 mb-2">
                      {teacher.subjects.map((subject, sIndex) => (
                        <span key={sIndex} className="bg-blue-100 px-2 py-1 rounded flex items-center">
                          {subject}
                          <button
                            className="ml-2"
                            onClick={() => handleSubjectRemove(index, subject)}
                          >
                            <X className="h-3 w-3" />
                          </button>
                        </span>
                      ))}
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add subject"
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && e.target.value) {
                            handleSubjectAdd(index, e.target.value);
                            e.target.value = '';
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* Availability Section */}
                  <div className="col-span-2">
                    <label>Availability</label>
                    {Object.keys(teacher.availability).map(day => (
                      <div key={day} className="flex items-center gap-4 mb-2">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={teacher.availability[day].available}
                            onChange={(e) => handleAvailabilityChange(index, day, e.target.checked)}
                            className="mr-2"
                          />
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                        </label>
                        {teacher.availability[day].available && (
                          <div className="flex gap-2">
                            <input
                              type="time"
                              className="form-control"
                              placeholder="Start Time"
                              onBlur={(e) => {
                                if (e.target.value) {
                                  handleTimeSlotAdd(index, day, {
                                    start: e.target.value,
                                    end: document.getElementById(`${day}-end-${index}`).value
                                  });
                                }
                              }}
                            />
                            <input
                              type="time"
                              id={`${day}-end-${index}`}
                              className="form-control"
                              placeholder="End Time"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  {/* Preferred Time Slots */}
                  <div className="col-span-2">
                    <label>Preferred Time Slots</label>
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Start Time"
                        onChange={(e) => {
                          const newTeachers = [...formData.teachers];
                          if (!newTeachers[index].preferredSlots.includes(e.target.value)) {
                            newTeachers[index].preferredSlots.push(e.target.value);
                            setFormData(prev => ({ ...prev, teachers: newTeachers }));
                          }
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {teacher.preferredSlots.map((slot, slotIndex) => (
                        <span key={slotIndex} className="bg-green-100 px-2 py-1 rounded flex items-center">
                          {slot}
                          <button
                            className="ml-2"
                            onClick={() => {
                              const newTeachers = [...formData.teachers];
                              newTeachers[index].preferredSlots = newTeachers[index].preferredSlots.filter(
                                (_, i) => i !== slotIndex
                              );
                              setFormData(prev => ({ ...prev, teachers: newTeachers }));
                            }}
                          >
                            <X className="h-3 w-3" />
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Subject Details Section */}
        <div className="row mb-4">
          <div className="col-12">
            <div className="flex justify-between items-center mb-3">
              <h3 className="text-lg font-semibold">Subject Details</h3>
              <button
                className="btn btn-primary btn-sm"
                onClick={addSubject}
              >
                <Plus className="h-4 w-4 mr-1" />
                Add Subject
              </button>
            </div>

            {formData.subjects.map((subject, index) => (
              <div key={index} className="border p-3 mb-3 rounded">
                <div className="flex justify-end">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeSubject(index)}
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="form-group">
                    <label>Subject Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={subject.name}
                      onChange={(e) => {
                        const newSubjects = [...formData.subjects];
                        newSubjects[index].name = e.target.value;
                        setFormData(prev => ({ ...prev, subjects: newSubjects }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Weekly Hours</label>
                    <input
                      type="number"
                      className="form-control"
                      value={subject.weeklyHours}
                      onChange={(e) => {
                        const newSubjects = [...formData.subjects];
                        newSubjects[index].weeklyHours = e.target.value;
                        setFormData(prev => ({ ...prev, subjects: newSubjects }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Preferred Time Slot (Optional)</label>
                    <input
                      type="time"
                      className="form-control"
                      value={subject.preferredTimeSlot}
                      onChange={(e) => {
                        const newSubjects = [...formData.subjects];
                        newSubjects[index].preferredTimeSlot = e.target.value;
                        setFormData(prev => ({ ...prev, subjects: newSubjects }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Teacher Assigned</label>
                    <select
                      className="form-control"
                      value={subject.teacherAssigned}
                      onChange={(e) => {
                        const newSubjects = [...formData.subjects];
                        newSubjects[index].teacherAssigned = e.target.value;
                        setFormData(prev => ({ ...prev, subjects: newSubjects }));
                      }}
                    >
                      <option value="">Select Teacher</option>
                      {formData.teachers.map((teacher, tIndex) => (
                        <option key={tIndex} value={teacher.name}>{teacher.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-span-2">
                    <label>Room/Lab/Facility Requirements</label>
                    <textarea
                      className="form-control"
                      value={subject.facilityRequirements}
                      onChange={(e) => {
                        const newSubjects = [...formData.subjects];
                        newSubjects[index].facilityRequirements = e.target.value;
                        setFormData(prev => ({ ...prev, subjects: newSubjects }));
                      }}
                      placeholder="Enter any specific room or facility requirements"
                      rows="3"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Room/Facility Details Section */}
        <div className="row mb-4">
          <div className="col-12">
            <div className="flex justify-between items-center mb-3">
              <h3 className="text-lg font-semibold">Room/Facility Details</h3>
              <button
                className="btn btn-primary btn-sm"
                onClick={addRoom}
              >
                <Plus className="h-4 w-4 mr-1" />
                Add Room
              </button>
            </div>

            {formData.rooms.map((room, index) => (
              <div key={index} className="border p-3 mb-3 rounded">
                <div className="flex justify-end">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeRoom(index)}
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="form-group">
                    <label>Room Name/Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={room.name}
                      onChange={(e) => {
                        const newRooms = [...formData.rooms];
                        newRooms[index].name = e.target.value;
                        setFormData(prev => ({ ...prev, rooms: newRooms }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Room Type</label>
                    <select
                      className="form-control"
                      value={room.type}
                      onChange={(e) => {
                        const newRooms = [...formData.rooms];
                        newRooms[index].type = e.target.value;
                        setFormData(prev => ({ ...prev, rooms: newRooms }));
                      }}
                    >
                      <option value="">Select Type</option>
                      <option value="classroom">Classroom</option>
                      <option value="lab">Lab</option>
                      <option value="auditorium">Auditorium</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Room Capacity</label>
                    <input
                      type="number"
                      className="form-control"
                      value={room.capacity}
                      onChange={(e) => {
                        const newRooms = [...formData.rooms];
                        newRooms[index].capacity = e.target.value;
                        setFormData(prev => ({ ...prev, rooms: newRooms }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Available Equipment</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g., Projector, Lab Equipment"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && e.target.value) {
                          const newRooms = [...formData.rooms];
                          newRooms[index].equipment.push(e.target.value);
                          setFormData(prev => ({ ...prev, rooms: newRooms }));
                          e.target.value = '';
                        }
                      }}
                    />
                    <div className="flex flex-wrap gap-2 mt-2">
                      {room.equipment.map((item, eIndex) => (
                        <span key={eIndex} className="bg-gray-100 px-2 py-1 rounded flex items-center">
                          {item}
                          <button
                            className="ml-2"
                            onClick={() => {
                              const newRooms = [...formData.rooms];
                              newRooms[index].equipment = newRooms[index].equipment.filter(
                                (_, i) => i !== eIndex
                              );
                              setFormData(prev => ({ ...prev, rooms: newRooms }));
                            }}
                          >
                            <X className="h-3 w-3" />
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Student/Classroom Details Section */}
        <div className="row mb-4">
          <div className="col-12">
            <div className="flex justify-between items-center mb-3">
              <h3 className="text-lg font-semibold">Student/Classroom Details</h3>
              <button
                className="btn btn-primary btn-sm"
                onClick={addClassroom}
              >
                <Plus className="h-4 w-4 mr-1" />
                Add Classroom
              </button>
            </div>

            {formData.classrooms.map((classroom, index) => (
              <div key={index} className="border p-3 mb-3 rounded">
                <div className="flex justify-end">
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeClassroom(index)}
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="form-group">
                    <label>Grade/Class Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={classroom.gradeName}
                      onChange={(e) => {
                        const newClassrooms = [...formData.classrooms];
                        newClassrooms[index].gradeName = e.target.value;
                        setFormData(prev => ({ ...prev, classrooms: newClassrooms }));
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Number of Students</label>
                    <input
                      type="number"
                      className="form-control"
                      value={classroom.studentCount}
                      onChange={(e) => {
                        const newClassrooms = [...formData.classrooms];
                        newClassrooms[index].studentCount = e.target.value;
                        setFormData(prev => ({ ...prev, classrooms: newClassrooms }));
                      }}
                    />
                  </div>

                  {/* Subject Groups */}
                  <div className="col-span-2">
                    <div className="flex justify-between items-center mb-2">
                      <label>Subject-Wise Groups</label>
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => addSubjectGroup(index)}
                      >
                        <Plus className="h-3 w-3 mr-1" />
                        Add Group
                      </button>
                    </div>
                    {classroom.subjectGroups.map((group, groupIndex) => (
                      <div key={groupIndex} className="grid grid-cols-3 gap-4 mb-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            value={group.subject}
                            onChange={(e) => {
                              const newClassrooms = [...formData.classrooms];
                              newClassrooms[index].subjectGroups[groupIndex].subject = e.target.value;
                              setFormData(prev => ({ ...prev, classrooms: newClassrooms }));
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Number of Groups"
                            value={group.numberOfGroups}
                            onChange={(e) => {
                              const newClassrooms = [...formData.classrooms];
                              newClassrooms[index].subjectGroups[groupIndex].numberOfGroups = e.target.value;
                              setFormData(prev => ({ ...prev, classrooms: newClassrooms }));
                            }}
                          />
                        </div>
                        <div className="form-group flex items-center">
                          <input
                            type="number"
                            className="form-control mr-2"
                            placeholder="Students per Group"
                            value={group.studentsPerGroup}
                            onChange={(e) => {
                              const newClassrooms = [...formData.classrooms];
                              newClassrooms[index].subjectGroups[groupIndex].studentsPerGroup = e.target.value;
                              setFormData(prev => ({ ...prev, classrooms: newClassrooms }));
                            }}
                          />
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => removeSubjectGroup(index, groupIndex)}
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="col-span-2">
                    <label>Special Needs or Requirements</label>
                    <textarea
                      className="form-control"
                      value={classroom.specialRequirements}
                      onChange={(e) => {
                        const newClassrooms = [...formData.classrooms];
                        newClassrooms[index].specialRequirements = e.target.value;
                        setFormData(prev => ({ ...prev, classrooms: newClassrooms }));
                      }}
                      rows="3"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

 {/* Constraints Section */}
 <div className="row mb-4">
          <div className="col-12">
            <h3 className="text-lg font-semibold mb-3">Constraints and Preferences</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label>Gap Between Classes (minutes)</label>
                <input
                  type="number"
                  className="form-control"
                  value={formData.constraints.gapBetweenClasses}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    constraints: { ...prev.constraints, gapBetweenClasses: e.target.value }
                  }))}
                />
              </div>
              <div className="form-group">
                <label>Max Consecutive Hours (Teachers)</label>
                <input
                  type="number"
                  className="form-control"
                  value={formData.constraints.maxConsecutiveHoursTeacher}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    constraints: { ...prev.constraints, maxConsecutiveHoursTeacher: e.target.value }
                  }))}
                />
              </div>
              <div className="form-group">
                <label>Max Consecutive Hours (Students)</label>
                <input
                  type="number"
                  className="form-control"
                  value={formData.constraints.maxConsecutiveHoursStudent}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    constraints: { ...prev.constraints, maxConsecutiveHoursStudent: e.target.value }
                  }))}
                />
              </div>

              {/* Break Times Section */}
              <div className="form-group col-span-2">
                <label>Break Times</label>
                {Object.entries(formData.constraints.breakTimes).map(([day, breaks]) => (
                  <div key={day} className="mb-2">
                    <h4 className="capitalize">{day}</h4>
                    {breaks.map((breakTime, index) => (
                      <div key={index} className="flex items-center gap-4 mb-1">
                        <input
                          type="time"
                          className="form-control w-40"
                          value={breakTime.start}
                          onChange={(e) => {
                            const newBreakTimes = { ...formData.constraints.breakTimes };
                            newBreakTimes[day][index].start = e.target.value;
                            setFormData(prev => ({
                              ...prev,
                              constraints: { ...prev.constraints, breakTimes: newBreakTimes }
                            }));
                          }}
                        />
                        <span>to</span>
                        <input
                          type="time"
                          className="form-control w-40"
                          value={breakTime.end}
                          onChange={(e) => {
                            const newBreakTimes = { ...formData.constraints.breakTimes };
                            newBreakTimes[day][index].end = e.target.value;
                            setFormData(prev => ({
                              ...prev,
                              constraints: { ...prev.constraints, breakTimes: newBreakTimes }
                            }));
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              {/* Special Events Section */}
              <div className="form-group col-span-2">
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-lg font-semibold">Add Special Events</h3>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setFormData(prev => ({
                        ...prev,
                        constraints: {
                          ...prev.constraints,
                          specialEvents: [
                            ...prev.constraints.specialEvents,
                            { name: "", date: "", startTime: "", endTime: "", room: "", teacher: "", description: "" }
                          ]
                        }
                      }));
                    }}
                  >
                    <Plus className="h-4 w-4 mr-1" />
                    Add Special Event
                  </button>
                </div>

                {formData.constraints.specialEvents.map((event, index) => (
                  <div key={index} className="grid grid-cols-3 gap-2 mb-2">
                    <input
                      type="text"
                      placeholder="Event Name"
                      className="form-control"
                      value={event.name}
                      onChange={(e) => {
                        const newEvents = [...formData.constraints.specialEvents];
                        newEvents[index].name = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          constraints: { ...prev.constraints, specialEvents: newEvents }
                        }));
                      }}
                    />
                    <input
                      type="date"
                      className="form-control"
                      value={event.date}
                      onChange={(e) => {
                        const newEvents = [...formData.constraints.specialEvents];
                        newEvents[index].date = e.target.value;
                        setFormData(prev => ({
                          ...prev,
                          constraints: { ...prev.constraints, specialEvents: newEvents }
                        }));
                      }}
                    />
                    <div className="flex gap-2">
                      <input
                        type="time"
                        className="form-control"
                        value={event.startTime}
                        onChange={(e) => {
                          const newEvents = [...formData.constraints.specialEvents];
                          newEvents[index].startTime = e.target.value;
                          setFormData(prev => ({
                            ...prev,
                            constraints: { ...prev.constraints, specialEvents: newEvents }
                          }));
                        }}
                      />
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          setFormData(prev => ({
                            ...prev,
                            constraints: {
                              ...prev.constraints,
                              specialEvents: prev.constraints.specialEvents.filter((_, i) => i !== index)
                            }
                          }));
                        }}
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>

        {/* General Settings Section */}
        <div className="row mb-4">
          <div className="col-12">
            <h3 className="text-lg font-semibold mb-3">General Settings</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label>Timetable Format</label>
                <select
                  className="form-control"
                  value={formData.settings.timetableFormat}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    settings: { ...prev.settings, timetableFormat: e.target.value }
                  }))}
                >
                  <option value="weekly">Weekly</option>
                  <option value="daily">Daily</option>
                </select>
              </div>
              <div className="form-group">
                <label>Language</label>
                <select
                  className="form-control"
                  value={formData.settings.language}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    settings: { ...prev.settings, language: e.target.value }
                  }))}
                >
                  <option value="english">English</option>
                  <option value="spanish">Spanish</option>
                  <option value="french">French</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button className="btn btn-primary">
            Generate Schedule
          </button>
        </div>
      </div>
    </>
  );
};

export default AutomationModal;
