import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import {
  ChevronDown,
  ChevronUp,
  Pencil,
  Plus,
  SendHorizonal,
  Trash2,
} from "lucide-react";
import TeacherModal from "../../modals/admin/TeacherModal";
import TeacherCards from "../../Components/admin/Cards/TeacherCards";
import { useDispatch, useSelector } from "react-redux";
import { fetchClasses, fetchClassesV1 } from "../../redux/classSlice";
import CustomDropdown from "../../Components/admin/CustomDropdown";

import {
  addTeacher,
  updateTeacher,
  deleteTeacher,
  fetchTeachers,
  setPageNumber,
} from "../../redux/teachersSlice";
import { Users, UserCheck, UserX, UserPlus } from "lucide-react";
import NotificationsModal from "../../Components/Common/NotificationModal";
import { uploadImage } from "../../utils";

const Teachers = () => {
  const { register, watch, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const password = watch("password");
  const teachers = useSelector((state) => state.teachers.teachers);
  const loading = useSelector((state) => state.teachers.loading);
  const error = useSelector((state) => state.teachers.error);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [currentPicture, setCurrentPicture] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [uploadType, setUploadType] = useState("");
  const [isNotificationsModal, setIsNotificationsModal] = useState(false);

  const [statusFilter, setStatusFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [designationFilter, setDesignationFilter] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);

  const totalCount = useSelector((state) => state.teachers.totalCount);
  const pageSize = useSelector((state) => state.teachers.pageSize);
  const pageNumber = useSelector((state) => state.teachers.pageNumber);

  const classes = useSelector((state) => state.classes.classes || []);

  useEffect(() => {
    dispatch(fetchTeachers({ pageNumber, pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  useEffect(() => {
    dispatch(fetchClassesV1({ pageNumber: 1, pageSize: 100 }));
  }, [dispatch]);

  

  const teacherCardData = [
    {
      title: "Total Teachers",
      icon: Users,
      number: totalCount || 0, 
      userStatus: "",
    },
    {
      title: "Active",
      icon: UserCheck,
      number:
        teachers?.filter((t) => t?.teacher?.userStatus === "Active").length ||
        0,
      userStatus: "Active",
    },
    {
      title: "Resigned",
      icon: UserX,
      number:
        teachers?.filter((t) => t?.teacher?.userStatus === "Resigned").length ||
        0,
      userStatus: "Resigned",
    },
    {
      title: "Unpaid Leaves",
      icon: UserX,
      number:
        teachers?.filter((t) => t?.teacher?.userStatus === "Unpaid Leaves")
          .length || 0,
      userStatus: "Unpaid Leaves",
    },
    {
      title: "Promoted",
      icon: UserPlus,
      number:
        teachers?.filter((t) => t?.teacher?.userStatus === "Promoted").length ||
        0,
      userStatus: "Promoted",
    },
  ];

  const onSubmit = async (data, selectedFile) => {
    let fileUrl = null;
    if (selectedFile) {
      fileUrl = await uploadImage(selectedFile);
    } else if (isEdit) {
      fileUrl = currentPicture;
    }

    const payload = {
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      cnic: data.cnic,
      location: data.location,
      dob: data.dob,
      gender: data.gender,
      joiningDate: data.joiningDate,
      userStatus: data.userStatus,
      maritalStatus: data.maritalStatus,
      profilePicture: fileUrl || null,
      professionalExperience: {
        organizationName: data.organizationName || "",
        designation: data.designation || "",
        startedOn: data.startedOn || null,
        endedOn: data.endedOn || null,
        lastSalaryDrawn: data.lastSalaryDrawn || 0,
        mainResponsibilities: data.mainResponsibilities || "",
        other: data.other || "",
        academicInstitute: data.academicInstitute || "",
        completedYear: data.completedYear || 0,
        majorSubject: data.majorSubject || "",
        cgpa: data.cgpa || 0,
        percentage: data.percentage || 0,
      },
      academicPerformance: {
        courseLevel: data.courseLevel || "",
        firstSemResult: data.firstSemResult || 0,
        secondSemResult: data.secondSemResult || 0,
        thirdSemResult: data.thirdSemResult || 0,
        overallAnnualResult: data.overallAnnualResult || 0,
      },
      classAssignment: (data.addedClasses || []).map((classData) => ({
        classId: classData.classId,
        classDetails: (classData.classDetails || []).map((detail) => ({
          sectionId: detail.sectionId,
          subjectId: detail.subjectId,
        })),
      })),
    };

    try {
      if (isEdit) {
        await dispatch(updateTeacher({ id: currentTeacherId, updatedTeacher: payload }));
        toast.success("Teacher updated successfully!");
      } else {
        await dispatch(addTeacher(payload));
        toast.success("Teacher added successfully!");
      }
    } catch (err) {
      // Handle error here
      if (err.response?.data?.message === "Teacher already registered") {
        toast.error("Teacher already registered!");
      } else {
        toast.error("Failed to add/update teacher: " + err.message);
      }
    }

    reset();
    setIsModalOpen(false);
    handleModalClose();
  };

  const handleEdit = (id) => {
    const teacher = teachers.find((teacher) => teacher.teacher?.id === id);

    setValue("firstName", teacher.teacher?.firstName);
    setValue("lastName", teacher.teacher?.lastName);
    setValue("email", teacher.teacher?.email);
    setValue("phone", teacher.teacher?.phone);
    setValue("location", teacher.teacher?.location);
    setValue("dob", formatDate(teacher.teacher?.dob));
    setValue("gender", teacher.teacher?.gender);
    setValue("password", "");
    setValue("joiningDate", formatDate(teacher.teacher?.joiningDate));
    setValue("job_nature", teacher.job_nature);
    setValue("cnic", teacher.teacher?.cnic);
    setValue("userStatus", teacher.teacher?.userStatus);
    setValue("IsActive", teacher.teacher?.isActive);
    setValue("employeeCode", teacher.teacher?.employeeCode);
    setValue("maritalStatus", teacher.teacher?.maritalStatus);
    setValue("spouse_name", teacher.spouse_name);
    setValue("spouse_cnic", teacher.spouse_cnic);
    setValue("kids_details", teacher.kids_details);

    // Academic details
    setValue("courseLevel", teacher.academicDetails?.courseLevel);
    setValue("class", teacher.academicDetails?.class);
    setValue("section", teacher.academicDetails?.section);
    setValue("subject", teacher.academicDetails?.subject);
    setValue("firstSemResult", teacher.academicDetails?.firstSemResult);
    setValue("secondSemResult", teacher.academicDetails?.secondSemResult);
    setValue("thirdSemResult", teacher.academicDetails?.thirdSemResult);
    setValue(
      "overallAnnualResult",
      teacher.academicDetails?.overallAnnualResult
    );
    setValue("attendance", teacher.attendance);
    setValue("teachers_observation", teacher.teachers_observation);

    // Professional experience
    setValue(
      "academicInstitute",
      teacher.professionalExperience?.academicInstitute
    );
    setValue("completedYear", teacher.professionalExperience?.completedYear);
    setValue("majorSubject", teacher.professionalExperience?.majorSubject);
    setValue("cgpa", teacher.professionalExperience?.cgpa);
    setValue("percentage", teacher.professionalExperience?.percentage);
    setValue(
      "organizationName",
      teacher.professionalExperience?.organizationName
    );
    setValue("designation", teacher.professionalExperience?.designation);
    setValue(
      "startedOn",
      formatDate(teacher.professionalExperience?.startedOn)
    );
    setValue("endedOn", formatDate(teacher.professionalExperience?.endedOn));
    setValue(
      "lastSalaryDrawn",
      teacher.professionalExperience?.lastSalaryDrawn
    );
    setValue(
      "mainResponsibilities",
      teacher.professionalExperience?.mainResponsibilities
    );
    setValue("other", teacher.professionalExperience?.other);
    setSelectedClasses(teacher.assignedClasses);
    setCurrentTeacherId(id);
    setCurrentPicture(teacher.teacher?.profilePicture);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleBulkUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch action to upload the bulk file
    // dispatch(addParentBulk(formData))
    //   .then(() => toast.success("Bulk upload successful!"))
    //   .catch(() => toast.error("Bulk upload failed!"));
  };

  const handleModalClose = () => {
    setUploadType(""); // Reset the dropdown value
    setIsEdit(false);
  };

  const handleUploadTypeChange = (type) => {
    setUploadType(type);
    setIsModalOpen(true);
  };

  const formatDate = (date) => {
    if (!date) return ""; // Handle null or undefined
    const parsedDate = new Date(date);
    return isNaN(parsedDate) ? "" : parsedDate.toISOString().split("T")[0];
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handleDelete = (id) => {
    dispatch(deleteTeacher(id))
      .unwrap()
      .then(() => toast.success("Teacher deleted successfully!"))
      .catch((err) => toast.error("Failed to delete teacher: " + err));
  };

  const handleCardClick = (card, index) => {
    setStatusFilter(card.userStatus);
    dispatch(fetchTeachers({ pageNumber: 1, pageSize }));
    setSelectedCard(index);
  };

  const handlePageChange = (newPage) => {
    if (newPage !== pageNumber) {
      dispatch(setPageNumber(newPage));
    }
  };

  const uniqueLocation = Array.from(
    new Set(teachers?.map((teacher) => teacher?.teacher?.location))
  );

  const uniqueSubjects = Array.from(
    new Set(
      classes?.flatMap((cls) =>
        cls.classSections?.flatMap((section) =>
          section.subjects?.map((subject) => subject.subjectName)
        )
      )
    )
  );

  const filterTeachers = (teachers = [], query) => {
    if (!query) {
      return teachers;
    }
    return teachers?.filter((teacher) => {
      const usernameMatch = teacher.teacher.firstName
        .toLowerCase()
        .includes(query.toLowerCase());
      const idMatch = teacher.id && teacher.id.toString().includes(query);
      return usernameMatch || idMatch;
    });
  };

  const filteredTeachers = filterTeachers(teachers, searchQuery);

  const teachersToDisplay = filteredTeachers.filter((teacher) => {
    const matchesStatus = statusFilter
      ? teacher?.teacher?.userStatus === statusFilter
      : true;
    return matchesStatus;
  });

  const totalPages = Math.ceil(totalCount / pageSize);
  const currentTeachers = teachersToDisplay;

  const toggleTeacherSelection = (teacherId, teacherName) => {
    setSelectedTeachers((prev) => {
      const isSelected = prev.some((teacher) => teacher.id === teacherId);
      if (isSelected) {
        return prev.filter((teacher) => teacher.id !== teacherId);
      } else {
        return [...prev, { id: teacherId, name: teacherName }];
      }
    });
  };

  const toggleSelectAll = () => {
    if (selectedTeachers.length === teachers.length) {
      setSelectedTeachers([]); // Deselect all
    } else {
      setSelectedTeachers(
        currentTeachers.map((teacher) => ({
          id: teacher.teacher?.id,
          name: `${teacher.teacher?.firstName} ${teacher.teacher?.lastName}`,
        }))
      );
    }
  };

  const onSendNotification = (payload) => {
  };

  const handleSubmitNotification = (payload) => {
    onSendNotification(payload);
    setIsModalOpen(false);
    setSelectedTeachers([]);
  };

  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Grid for Teacher Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-4">
        {teacherCardData.map((card, index) => (
          <div
            key={index}
            onClick={() => handleCardClick(card, index)}
            className="cursor-pointer"
          >
            <TeacherCards
              icon={card.icon}
              title={card.title}
              number={card.number}
              isSelected={selectedCard === index}
              className="truncate cursor-pointer"
            />
          </div>
        ))}
      </div>

      <div className="mb-4 flex gap-4">
        <div className="flex flex-grow items-end lg:col-span-2">
          <input
            type="text"
            placeholder="Search by name or ID"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              dispatch(fetchTeachers({ pageNumber: 1, pageSize }));
            }}
            className="w-full p-2 border rounded"
          />
        </div>

        {/* <div>
          <CustomDropdown
            options={uniqueStatuses} 
            value={statusFilter} 
            onChange={(value) => setStatusFilter(value)} 
            placeholder="School" 
          />
        </div> */}

        <div>
          <CustomDropdown
            options={uniqueLocation}
            value={locationFilter}
            onChange={(value) => setLocationFilter(value)}
            placeholder="All locations"
          />
        </div>

        <div>
          <CustomDropdown
            options={uniqueSubjects}
            value={designationFilter}
            onChange={(value) => setDesignationFilter(value)}
            placeholder="All subjects"
          />
        </div>
        <div className="sm:flex-[1_1_0%]">
          <select
            className="w-full bg-[#241763] text-white p-2 rounded"
            onChange={(e) => handleUploadTypeChange(e.target.value)}
            value={uploadType}
          >
            <option value="" disabled>
              Add Teacher
            </option>
            <option value="single">Add Teacher</option>
            <option value="bulk">Bulk Upload Teacher</option>
          </select>
        </div>
        <div
          className="bg-[#241763] p-2 rounded"
          onClick={() => setIsNotificationsModal(true)}
        >
          <SendHorizonal className="text-white w-6 h-6 group-hover:text-white" />
        </div>
      </div>

      <TeacherModal
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsModalOpen}
        register={register}
        password={password}
        reset={reset}
        currentPicture={currentPicture}
        classes={classes}
        selectedClasses={selectedClasses}
        handleBulkUpload={handleBulkUpload}
        uploadType={uploadType}
        onModalClose={handleModalClose}
      />

      {isNotificationsModal && (
        <NotificationsModal
          selectedItems={selectedTeachers}
          toggleItemSelection={toggleTeacherSelection}
          handleSubmitNotification={handleSubmitNotification}
          onClose={() => setIsNotificationsModal(false)}
        />
      )}

      {/* Table for Teacher List */}
      {loading ? (
        <div className="flex justify-center items-center h-40">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-[#241763]"></div>
          <span className="ml-2 text-[#241763]">Loading...</span>
        </div>
      ) : (
        <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
          <table className="table-auto h-90 w-full mt-6">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={
                      selectedTeachers.length > 0 &&
                      selectedTeachers.length === currentTeachers.length
                    }
                    onChange={toggleSelectAll}
                  />
                </th>
                <th>Profile</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Employee code</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {currentTeachers.map((teacher, index) => (
                <>
                  <tr
                    key={teacher.teacher?.id}
                    className="p-2 py-2 hover:bg-gray-200 space-y-2"
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedTeachers.some(
                          (t) => t.id === teacher.teacher?.id
                        )}
                        onChange={() =>
                          toggleTeacherSelection(
                            teacher.teacher?.id,
                            `${teacher.teacher?.firstName} ${teacher.teacher?.lastName}`
                          )
                        }
                      />
                    </td>
                    <td>
                      <img
                        src={teacher.teacher?.profilePicture}
                        alt="Teacher Profile"
                        className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                      />
                    </td>
                    <td>{`${teacher.teacher?.firstName} ${teacher.teacher?.lastName}`}</td>
                    <td>{teacher.teacher?.gender}</td>
                    <td>{teacher.teacher?.email}</td>

                    <td className="text-blue-500">{teacher.teacher?.phone}</td>
                    <td>{teacher.teacher?.employeeCode}</td>
                    <td>{teacher.teacher?.userStatus}</td>

                    <td className="space-x-2 flex items-center py-2">
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                        onClick={() => handleEdit(teacher.teacher?.id)}
                      >
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleDelete(teacher.teacher?.id)}
                      >
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>

                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer  hover:bg-[#241763]/60 group bg-[#B6A5C9]/50 p-2 rounded"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan="10">
                        <div className="bg-gray-50 p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                          {/* Personal Details Card */}
                          <div className="bg-white shadow rounded-lg p-6">
                            <h2 className="text-lg font-bold text-blue-500 mb-4">
                              Personal Details
                            </h2>
                            <table className="w-full text-sm">
                              <tbody>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    Location:
                                  </td>
                                  <td>{teacher.teacher?.location}</td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    Joining Date:
                                  </td>
                                  <td>{teacher.teacher?.joiningDate}</td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    CNIC:
                                  </td>
                                  <td>{teacher.teacher?.cnic}</td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    Marital Status:
                                  </td>
                                  <td>{teacher.teacher?.maritalStatus}</td>
                                </tr>
                                {/* {teacher.teacher?.maritalStatus ===
                                  "Married" && (
                                  <>
                                    <tr>
                                      <td className="font-medium text-gray-600">
                                        Spouse Name:
                                      </td>
                                      <td>{teacher.spouse_name}</td>
                                    </tr>
                                    <tr>
                                      <td className="font-medium text-gray-600">
                                        Spouse CNIC:
                                      </td>
                                      <td>{teacher.spouse_cnic}</td>
                                    </tr>
                                    <tr>
                                      <td className="font-medium text-gray-600">
                                        Kids Details:
                                      </td>
                                      <td>{teacher.kids_details}</td>
                                    </tr>
                                  </>
                                )} */}
                              </tbody>
                            </table>
                          </div>

                          {/* Academics Performance Card */}
                          <div className="bg-white shadow rounded-lg p-6">
                            <h2 className="text-lg font-bold text-blue-500 mb-4">
                              Academics Performance
                            </h2>
                            <table className="w-full text-sm">
                              <tbody>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    Course Level:
                                  </td>
                                  <td>
                                    {teacher.academicPerformance?.courseLevel}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    First Sem Result:
                                  </td>
                                  <td>
                                    {
                                      teacher.academicPerformance
                                        ?.firstSemResult
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    Second Sem Result:
                                  </td>
                                  <td>
                                    {
                                      teacher.academicPerformance
                                        ?.secondSemResult
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    Overall Annual Result:
                                  </td>
                                  <td>
                                    {
                                      teacher.academicPerformance
                                        ?.overallAnnualResult
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          {/* Assigned Classes Card */}
                          <div className="bg-white shadow rounded-lg p-6 h-60 overflow-y-auto">
                            <h2 className="text-lg font-bold text-blue-500 mb-4">
                              Assigned Classes
                            </h2>
                            {teacher.assignedClasses?.map((classItem, idx) => (
                              <div
                                key={idx}
                                className="mb-4 border-b border-gray-200 pb-4"
                              >
                                <p className="font-bold text-gray-800">
                                  Class  {classItem.className}
                                </p>
                                {classItem.subjects?.map(
                                  (subject, detailIdx) => (
                                    <div key={detailIdx}>
                                      <p>
                                        <span className="font-medium text-gray-600">
                                          Section :{" "}
                                        </span>
                                        {subject.sectionName}
                                      </p>
                                      <p>
                                        <span className="font-medium text-gray-600">
                                          Subject :{" "}
                                        </span>
                                        {subject.subjectName}
                                      </p>
                                    </div>
                                  )
                                )}
                              </div>
                            ))}
                          </div>

                          {/* Professional Experience */}
                          <div className="bg-white shadow rounded-lg p-6">
                            <h2 className="text-lg font-bold text-blue-500 mb-4">
                              Professional Experience
                            </h2>
                            <table className="w-full text-sm">
                              <tbody>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    Organization:
                                  </td>
                                  <td>
                                    {
                                      teacher.professionalExperience
                                        ?.organizationName
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-gray-600">
                                    Designation:
                                  </td>
                                  <td>
                                    {
                                      teacher.professionalExperience
                                        ?.designation
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(pageNumber - 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={pageNumber === 1}
        >
          Previous
        </button>

        <div className="flex">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`px-3 py-1 mx-1 border rounded ${
                pageNumber === i + 1 ? "bg-[#241763] text-white" : "bg-gray-200"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() => handlePageChange(pageNumber + 1)}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={pageNumber === totalPages}
        >
          Next
        </button>
      </div>

      <Toaster />
    </div>
  );
};

export default Teachers;
