import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Pencil, Trash2, ChevronDown, X, Plus, Settings } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import ClassesModal from "../../modals/admin/ClassesModal";
import AutomationModal from "../../modals/admin/AutomationModal";
import {
  fetchClasses,
  addClassAsync,
  updateClassAsync,
  deleteClass,
  setPageNumber,
} from "../../redux/classSlice";

const Class = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.classes.classes || []);
  const loading = useSelector((state) => state.classes.loading);
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);
  const [isAutomationModalOpen, setIsAutomationModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentClassId, setCurrentClassId] = useState(null);
  const [expandedClassIds, setExpandedClassIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const totalCount = useSelector((state) => state.classes.totalCount);
  const pageSize = useSelector((state) => state.classes.pageSize);
  const pageNumber = useSelector((state) => state.classes.pageNumber);

  useEffect(() => {
    dispatch(fetchClasses({ pageNumber, pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  const onSubmit = async (data) => {
    const payload = {
      className: data.className,
      classType: data.classType,
      startedSession: data.startedSession,
      endSession: data.endSession,
      sections: data.sections,
      creditHours: data.creditHours,
      subjects: data.classSubjects,
    };
  
    try {
      if (isEdit && currentClassId) {
        await dispatch(updateClassAsync({ id: currentClassId, payload })).unwrap();
      } else {
        await dispatch(addClassAsync(payload)).unwrap();
        dispatch(fetchClasses({ pageNumber, pageSize })); // Ensure data refresh
      }
      reset();
      setIsClassModalOpen(false);
      setIsEdit(false);
    } catch (error) {
      console.error("Error:", error); // Log the error for debugging
      toast.error(error || "Something went wrong. Please try again.");
    }
  };

  const handleEdit = (cls) => {
    setIsEdit(true);
    setCurrentClassId(cls.id);
    setIsClassModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteClass(id)).unwrap();
      toast.success("Class deleted successfully!");
    } catch (error) {
      toast.error("Class is already assigned to teachers & students");
    }
  };

  const toggleExpand = (id) => {
    setExpandedClassIds((prev) =>
      prev.includes(id)
        ? prev.filter((classId) => classId !== id)
        : [...prev, id]
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage !== pageNumber) {
      dispatch(setPageNumber(newPage)); // Only set the page number in the Redux store
    }
  };

  const filterClasses = (classes, query) => {
    if (!query) return classes;
    return classes.filter((cls) => {
      const classNameMatch = cls.className
        .toLowerCase()
        .includes(query.toLowerCase());
      const classTypeMatch = cls.classType
        .toLowerCase()
        .includes(query.toLowerCase());
      return classNameMatch || classTypeMatch;
    });
  };

  const filteredClasses = filterClasses(classes, searchQuery);
  const classesToDisplay = filteredClasses;
  const totalPages = Math.ceil(totalCount / pageSize);
  const currentClasses = classesToDisplay;

  return (
    <div className="container mx-auto p-6 border border-[#241763]">
      <h2 className="text-lg font-semibold">
        Total Classes: {totalCount || 0}
      </h2>

      <div className="flex justify-between mb-4 space-x-4">
        <input
          type="text"
          placeholder="Search by Class Name or Class Type"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            dispatch(fetchClasses({ pageNumber: 1, pageSize }));
          }}
          className="w-3/4 p-2 border rounded"
        />
        <div className="flex space-x-2 w-1/4">
          <button
            className="flex items-center justify-center flex-1 bg-[#241763] text-white p-2 rounded space-x-2"
            onClick={() => {
              setIsClassModalOpen(true);
              setIsEdit(false);
            }}
          >
            <Plus className="h-4 w-4" />
            <span>Add Class</span>
          </button>
          <button
            className="flex items-center justify-center flex-1 bg-[#241763] text-white p-2 rounded space-x-2"
            onClick={() => setIsAutomationModalOpen(true)}
          >
            <Settings className="h-4 w-4" />
            <span>Automation</span>
          </button>
        </div>
      </div>

      {isClassModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-h-[80vh] overflow-y-auto">
            <div className="flex justify-end">
              <button
                className=" bg-red-500 text-white rounded"
                onClick={() => setIsClassModalOpen(false)}
              >
                <X />
              </button>
            </div>
            <ClassesModal
              currentClass={
                isEdit ? classes.find((cls) => cls.id === currentClassId) : null
              }
              register={register}
              onSubmit={onSubmit}
              isEdit={isEdit}
            />
          </div>
        </div>
      )}

      {isAutomationModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-5/5 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white rounded"
                onClick={() => setIsAutomationModalOpen(false)}
              >
                <X />
              </button>
            </div>
            <AutomationModal
              currentClass={
                isEdit ? classes.find((cls) => cls.id === currentClassId) : null
              }
              onSubmit={onSubmit}
            />
          </div>
        </div>
      )}

      {loading ? (
        <div className="flex justify-center items-center h-40">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-[#241763]"></div>
          <span className="ml-2 text-[#241763]">Loading...</span>
        </div>
      ) : (
        <div className="max-h-[70vh] overflow-x-auto overflow-y-auto">
          <table className="table-auto w-full mt-6">
            <thead className="py-4 border-b font-bold">
              <tr>
                <th>Class Name</th>
                <th>Class Type</th>
                <th>Credit Hours</th>
                <th>Sections</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {currentClasses?.map((cls) => (
                <React.Fragment key={cls.id}>
                  <tr className="p-2 hover:bg-gray-200">
                    <td>{cls.className}</td>
                    <td>{cls.classType}</td>
                    <td>{cls.creditHours}</td>
                    <td>{cls.sections}</td>
                    <td>{cls.startedSession}</td>
                    <td>{cls.endSession}</td>
                    <td className="space-x-2 flex items-center py-2">
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded mr-2 hover:bg-[#241763]/60 group"
                        onClick={() => handleEdit(cls)}
                      >
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleDelete(cls.id)}
                      >
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => toggleExpand(cls.id)}
                      >
                        <ChevronDown
                          className={`w-4 h-4 group-hover:text-white transition-transform ${
                            expandedClassIds.includes(cls.id) ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                    </td>
                  </tr>
                  {expandedClassIds.includes(cls.id) && (
                    <tr>
                      <td colSpan="10">
                        <div className="bg-gray-100 p-4">
                          {cls.sections && cls.sections.length > 0 ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
                              {cls.sections.map((section) => (
                                <div
                                  key={section}
                                  className="bg-white shadow-md rounded-lg p-4 border border-gray-200"
                                >
                                  <h2 className="font-bold text-lg text-blue-400 mb-2">
                                    Section: {section}
                                  </h2>
                                  {cls.subjects && cls.subjects.length > 0 ? (
                                    <table className="w-full border-collapse">
                                      <thead>
                                        <tr>
                                          <th className="border-b border-gray-300 p-2 text-left text-sm font-semibold">
                                            Subject Name
                                          </th>
                                          <th className="border-b border-gray-300 p-2 text-left text-sm font-semibold">
                                            Day
                                          </th>
                                          <th className="border-b border-gray-300 p-2 text-left text-sm font-semibold">
                                            Time
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {cls.subjects.map((subject) =>
                                          subject.days
                                            .filter(
                                              (day) => day.section === section
                                            )
                                            .map((day, index) => (
                                              <tr
                                                key={`${subject.name}-${index}`}
                                              >
                                                <td className="border-b border-gray-200 p-2">
                                                  {subject.name}
                                                </td>
                                                <td className="border-b border-gray-200 p-2">
                                                  {day.day}
                                                </td>
                                                <td className="border-b border-gray-200 p-2">
                                                  {day.startTime} - {day.endTime}
                                                </td>
                                              </tr>
                                            ))
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <div className="text-gray-500 text-sm">
                                      No subjects available for this section.
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="bg-white shadow-md rounded-lg p-4 border border-gray-200">
                              <h2 className="font-bold text-lg text-gray-600 text-center">
                                No sections available for this class.
                              </h2>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange((prev) => Math.max(prev - 1, 1))}
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={pageNumber === 1}
        >
          Previous
        </button>

        <div className="flex">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`px-3 py-1 mx-1 ${
                pageNumber === i + 1
                  ? "bg-[#241763] text-white"
                  : "bg-gray-200 text-gray-700"
              } rounded`}
            >
              {i + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() =>
            handlePageChange((prev) => Math.min(prev + 1, totalPages))
          }
          className="px-3 py-1 bg-[#241763] text-white rounded"
          disabled={pageNumber === totalPages}
        >
          Next
        </button>
      </div>

      <Toaster />
    </div>
  );
};

export default Class;
