import React from "react";
import { pauseBtn, leacture } from "../../Constant/svgs";
import { useNavigate } from "react-router-dom";

const CoursesList = (props) => {
  const navigate = useNavigate();
  const {
    key,
    item
  } = props;

  return (
    <div className="courseBox  w-100 mt-3 customShadow d-flex" key={key}>
      <div className="thubnailCourse">
        <img src={item.coverImageUrl} alt="coursesImg" />
        <span>{item.plan}</span>
      </div>
      <div className="courseDetails">
        <div className="courseDetalsTxt">
          <h6>{item.name}</h6>
          <p>{item.description}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center ">
          <div className="w-50">
            <div className="progress">
              <div className="bar" style={{ width: '70%' }}></div>
            </div>
            <div className="lctDuration">
              {leacture}
              <p>15 Lessons (10h 5m)</p>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary continueBtn bgMain"
            onClick={() => navigate(`/course/${item.id}`, { state: { ...item } })}
          >
            Continue {pauseBtn}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoursesList;
