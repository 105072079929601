import React, {useEffect} from "react";
import { useParentContext } from "../../ContextAPI/ParentContext"; 
import { setUser,fetchUsers  } from "../../redux/ParentSlice";
import { useDispatch } from "react-redux";

const ChildCards = () => {
  const { users ,loading} = useParentContext(); 
  const dispatch = useDispatch();
  console.log("users", users)
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);
  return (
    <div className="d-flex gap-3 justify-content-start flex-wrap smFlexScreen mt-3">
      {users.map((child, index) => (
        <div
          key={index}
          className={` ${
            child.isActive ? "childChipActive" : "childChip"
          } d-flex flex-row gap-2 align-items-center cursor-pointer`}
          onClick={() => dispatch(setUser(index))}
        >
          <div className="childAvatar">
            <img src={child.avatar} alt={child.name} className="h-100 w-100" />
          </div>
          <div className="d-flex flex-column">
            <h6 className={`p-0 m-0 ${child.isActive ? "text-white" : ""}`}>
              {child.name}
            </h6>
            <span>{child.class}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChildCards;
