import React, { useState, useRef } from "react";
import { paperPlaneSvg } from "../../Constant/svgs";
import { SidebarLogo, primaryLogo } from "../../Constant/images";
import { postRequest } from "../../services";
import { VideoCameraOutlined } from "@ant-design/icons"; // Import the Video Icon
import { useNavigate } from "react-router-dom";

const Chatbot = () => {

  const messagesRef = useRef(null);
  const [content, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const navigate = useNavigate();
  const handleSentMessage = async () => {
    if (!content.trim()) return; // Avoid sending empty messages

    const newMessage = {
      content,
      subject: "sender",
    };
    setChat((prevChat) => [...prevChat, newMessage]);
    setMessage("");

    if (messagesRef.current) {
      const { scrollHeight, clientHeight } = messagesRef.current;
      messagesRef.current.scrollTop = scrollHeight - clientHeight + 2;
    }

    try {
      // Send the content to the API
      const response = await postRequest("/api/Chatbot/message", { content });
      const data = response.data;

      const botReply = data.response; // Assuming 'response' is a string or renderable content

      setChat((prevChat) => [
        ...prevChat,
        { content: botReply, subject: "reciever" },
      ]);
    } catch (error) {
      console.error("Error sending content:", error);
      // Optionally handle the error by showing a failure message
    }
  };

  // Handle Enter key press to send the message
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSentMessage();
    }
  };

  return (
    <div className="chatbox">
      <div className="chatbotHeader text-white d-flex justify-content-between align-items-center">
        <div className="text-white d-flex justify-content-start gap-2 align-items-center">
          <div className="chatbotLogoWrapper">
            <img src={SidebarLogo} className="h-100 w-100" alt="chatbot" />
          </div>
          <div className="d-flex flex-column justify-content-center p-0 m-0">
            <h6 className="p-0 m-0 fw-bold">Wiser-Adviser</h6>
            <div className="d-flex gap-1 align-items-center">
              <div className="onlineDot"></div>
              <p className="p-0 m-0">online</p>
            </div>
          </div>
        </div>
        {/* Video Icon - Positioned on the right */}
        <div className="videoIconWrapper">
          <VideoCameraOutlined
            style={{
              fontSize: "24px",
              color: "#fff", // Set icon color
              cursor: "pointer",
            }}
            onClick={() => navigate("/video-chat")} // Example click handler
          />
        </div>
      </div>
      {/* Chat area */}
      <div
        className="chats d-flex flex-column w-100 gap-4 py-2 px-2"
        ref={messagesRef}
      >
        {chat.map((message, index) => (
          <div
            key={index}
            className={`w-100 d-flex position-relative ${message.subject === "reciever"
                ? "justify-content-start"
                : "justify-content-end"
              }`}
          >
            <div
              className={`w-auto ${message.subject === "sender"
                  ? "sentMessageBubble"
                  : "recievedMessageBubble"
                }`}
            >
              <div
                className={`${message.subject === "sender"
                    ? "chatBotSentMessages"
                    : "RecievedMessageBubbleChatBot"
                  }`}
              >
                {message.subject === "reciever" && (
                  <div className="d-flex align-items-center gap-2">
                    <div className="chatbotAvatarWrapper">
                      <img src={primaryLogo} />
                    </div>
                    <div className="chatboxRecievedMessages">
                      {message.content}
                    </div>
                  </div>
                )}
                {message.subject === "sender" && <div>{message.content}</div>}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="divider"></div>
      <div className="footer">
        <input
          type="text"
          placeholder="Ask your question..."
          onChange={(e) => setMessage(e.target.value)}
          value={content}
          onKeyDown={handleKeyDown} // Listen for Enter key press
        />
        <span className="m-0 ps-3 cursor-pointer" onClick={handleSentMessage}>
          {paperPlaneSvg}
        </span>
      </div>
    </div>
  );
};

export default Chatbot;
