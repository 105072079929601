import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchStats } from "../../redux/dashboardSlice";
import Card from "../../Components/admin/Cards/Cards";
import LineChartComponent from "../../Components/admin/Cards/LineChartComponent";
import AreaChartComponent from "../../Components/admin/Cards/AreaChartComponent";
import AcademicResultsChart from "../../Components/admin/Cards/AcademicResultsChart";
import AttendanceChart from "../../Components/admin/Cards/AttendanceChart";
import WelcomeSection from "../../Components/Common/WelcomeSection";
import TeacherSatisfactionChart from "../../Components/admin/Cards/TeacherSatisfactionChart";
import VolumesChart from "../../Components/admin/Cards/VolumesChart";
import FinancialChart from "../../Components/admin/Cards/FinancialChart";
import CustomDropdown from "../../Components/admin/CustomDropdown";
import { chartData } from "../../Constant/charts";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { stats, loading, error } = useSelector((state) => state.dashboard);

  const user = JSON.parse(localStorage.getItem("user"));

  const [campusNames, setCampusNames] = React.useState([]);
  const [classNames, setClassNames] = React.useState([]);
  const [sectionNames, setSectionNames] = React.useState([]);
  const [subjectNames, setSubjectNames] = React.useState([]);

  const [campusFilter, setCampusFilter] = React.useState("");
  const [classFilter, setClassFilter] = React.useState("");
  const [sectionFilter, setSectionFilter] = React.useState("");
  const [subjectFilter, setSubjectFilter] = React.useState("");

  // Fetch the statistics when the component mounts
  useEffect(() => {
    dispatch(fetchStats());
  }, [dispatch]);

  // Fetch filter data
  useEffect(() => {
    fetch("/filtersData.json")
      .then((response) => response.json())
      .then((data) => {
        setCampusNames(data.campusNames);
        setClassNames(data.classNames);
        setSectionNames(data.sectionNames);
        setSubjectNames(data.subjectNames);
      })
      .catch((error) => {
        console.error("Error fetching the filter data: ", error);
      });
  }, []);

  const cardData = stats
    ? [
        {
          title: "Total Teachers",
          value: stats.totalTeachers || 0,
          percent: `${stats.percentageIncreaseTeachers || 0}%`,
          color: "bg-green-500",
          increase: "Since last period",
          progressValue: stats.percentageIncreaseTeachers || 0,
        },
        {
          title: "Total Students",
          value: stats.totalStudents || 0,
          percent: `${stats.percentageIncreaseStudents || 0}%`,
          color: "bg-blue-500",
          increase: "Since last period",
          progressValue: stats.percentageIncreaseStudents || 0,
        },
        {
          title: "Total Courses",
          value: stats.totalCourses || 0,
          percent: `${stats.percentageIncreaseCourses || 0}%`,
          color: "bg-yellow-500",
          increase: "Since last period",
          progressValue: stats.percentageIncreaseCourses || 0,
        },
        {
          title: "Fees Collected",
          value: `$${stats.totalFeeCollected?.toLocaleString() || 0}`,
          percent: `${stats.percentageIncreaseFeeCollected || 0}%`,
          color: "bg-red-500",
          increase: "Since last period",
          progressValue: stats.percentageIncreaseFeeCollected || 0,
        },
      ]
    : [];

  // Display a loading or error message if needed
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-6">
      <WelcomeSection user={user} />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 my-8">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            value={card.value}
            percent={card.percent}
            color={card.color}
            progressValue={card.progressValue}
            increase={card.increase}
          />
        ))}
      </div>

      {/* Filter Section */}
      <div className="flex flex-row justify-between mb-6 w-full space-x-4">
        {/* Campus Filter */}
        <div className="w-1/4">
          <CustomDropdown
            options={campusNames}
            value={campusFilter}
            onChange={(value) => setCampusFilter(value)}
            placeholder="All Campuses"
          />
        </div>

        {/* Class Filter */}
        <div className="w-1/4">
          <CustomDropdown
            options={classNames}
            value={classFilter}
            onChange={(value) => setClassFilter(value)}
            placeholder="All Classes"
          />
        </div>

        {/* Section Filter */}
        <div className="w-1/4">
          <CustomDropdown
            options={sectionNames}
            value={sectionFilter}
            onChange={(value) => setSectionFilter(value)}
            placeholder="All Sections"
          />
        </div>

        {/* Subject Filter */}
        <div className="w-1/4">
          <CustomDropdown
            options={subjectNames}
            value={subjectFilter}
            onChange={(value) => setSubjectFilter(value)}
            placeholder="All Subjects"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FinancialChart />
        <VolumesChart filter="all" />

        <AcademicResultsChart />
        <AttendanceChart title={"Student Attendance"} filter="all" />
        <AttendanceChart title={"Teacher Attendance"} filter="all" />
        <TeacherSatisfactionChart />
        <LineChartComponent data={chartData} />
        <AreaChartComponent data={chartData} />
      </div>
    </div>
  );
};

export default Dashboard;
