import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import JobModal from '../../modals/Hrms/JobModal';

const Recruitment = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPostJobModalOpen, setIsPostJobModalOpen] = useState(false);
  const [jobs, setJobs] = useState([
    {
      id: 1,
      title: 'Business Development Manager',
      vacancies: 2,
      lastDate: '2023-06-15',
      description: 'Responsible for driving business growth and managing partnerships.',
      educationalRequirement: 'Bachelor’s degree in Business Administration or related field.',
      skillSet: 'Excellent communication, negotiation, and strategic planning skills.',
      dutiesAndResponsibilities: 'Identify business opportunities, build relationships, and develop strategies.'
    },
    {
      id: 2,
      title: 'Content Writing Internee',
      vacancies: 2,
      lastDate: '2023-06-02',
      description: 'Assist in creating engaging content for digital platforms.',
      educationalRequirement: 'Pursuing a degree in English, Journalism, or related field.',
      skillSet: 'Strong writing and research skills, attention to detail.',
      dutiesAndResponsibilities: 'Write articles, edit content, and collaborate with the marketing team.'
    },

    {
      id: 7,
      title: 'UX/UI Designer',
      vacancies: 2,
      lastDate: '',
      description: 'Design and improve user interfaces and experiences.',
      educationalRequirement: 'Bachelor’s degree in Design or related field.',
      skillSet: 'Proficiency in design tools like Adobe XD, Figma, and strong portfolio.',
      dutiesAndResponsibilities: 'Create wireframes, prototypes, and collaborate with developers.'
    },
  ]);

  const filteredJobs = jobs.filter((job) =>
    job.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCardClick = (job) => {
    setSelectedJob(job);
  };

  const closeModal = () => {
    setSelectedJob(null);
  };

  const openPostJobModal = () => {
    setIsPostJobModalOpen(true);
  };

  const closePostJobModal = () => {
    setIsPostJobModalOpen(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const newJob = {
      id: jobs.length + 1,
      title: data.postingTitle,
      vacancies: data.numberOfPosts,
      lastDate: data.targetDate || 'Not specified',
      description: data.description,
      educationalRequirement: data.educationalRequirement,
      skillSet: data.skillSet,
      dutiesAndResponsibilities: data.dutiesAndResponsibilities,
    };

    setJobs([...jobs, newJob]);
    setSelectedJob(newJob); 
    reset(); // Reset the form
    setIsPostJobModalOpen(false); 
  };

  return (
    <div className="px-6 py-8">
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Search for a job..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 border border-gray-300 rounded-md w-1/2"
        />
        <button
          onClick={openPostJobModal}
          className="bg-[#241763] text-white px-4 py-2 rounded-md shadow-md hover:bg-[#241763]"
        >
          Post Job
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {filteredJobs.map((job) => (
          <div
            key={job.id}
            className="p-4 bg-white shadow-md rounded-lg hover:shadow-lg cursor-pointer"
            onClick={() => handleCardClick(job)}
          >
            <h2 className="text-lg font-bold text-gray-800">{job.title}</h2>
            <p className="text-sm text-gray-600">Total {job.vacancies} vacancies</p>
            <p className="text-sm text-gray-500">
              Last date to apply is: {job.lastDate || 'Not specified'}
            </p>
          </div>
        ))}
      </div>
      {selectedJob && <JobModal job={selectedJob} onClose={closeModal} />}
      {isPostJobModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md w-full max-w-2xl overflow-y-auto max-h-full">
            <h2 className="text-2xl font-bold mb-4">Post a New Job</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <input
                  type="text"
                  {...register('postingTitle', { required: 'Posting title is required' })}
                  placeholder="Posting Title"
                  className="p-2 border border-gray-300 rounded-md w-full"
                />
                <input
                  type="number"
                  {...register('numberOfPosts', { required: 'Number of posts is required' })}
                  placeholder="Number of Posts"
                  className="p-2 border border-gray-300 rounded-md w-full"
                />
                <input
                  type="date"
                  {...register('targetDate')}
                  className="p-2 border border-gray-300 rounded-md w-full"
                />
                <textarea
                  type="text"
                  {...register('educationalRequirement', { required: 'Educational requirement is required' })}
                  placeholder="Educational Requirement"
                  className="p-2 border border-gray-300 rounded-md w-full"
                ></textarea>
                <textarea
                  type="text"
                  {...register('skillSet', { required: 'Skill set is required' })}
                  placeholder="Skill Set"
                  className="p-2 border border-gray-300 rounded-md w-full"
                ></textarea>
                <textarea
                  type="text"
                  {...register('dutiesAndResponsibilities', { required: 'Duties and responsibilities are required' })}
                  placeholder="Duties and Responsibilities"
                  className="p-2 border border-gray-300 rounded-md w-full"
                ></textarea>
              </div>
              <textarea
                {...register('description', { required: 'Description is required' })}
                placeholder="Description"
                className="p-2 border border-gray-300 rounded-md w-full mb-4"
                rows="4"
              ></textarea>
              {errors.postingTitle && (
                <p className="text-red-500 text-sm">{errors.postingTitle.message}</p>
              )}
              {errors.numberOfPosts && (
                <p className="text-red-500 text-sm">{errors.numberOfPosts.message}</p>
              )}
              {errors.educationalRequirement && (
                <p className="text-red-500 text-sm">{errors.educationalRequirement.message}</p>
              )}
              {errors.skillSet && (
                <p className="text-red-500 text-sm">{errors.skillSet.message}</p>
              )}
              {errors.dutiesAndResponsibilities && (
                <p className="text-red-500 text-sm">{errors.dutiesAndResponsibilities.message}</p>
              )}
              {errors.description && (
                <p className="text-red-500 text-sm">{errors.description.message}</p>
              )}
              <div className="flex justify-end gap-4 mt-4">
                <button
                  type="button"
                  onClick={closePostJobModal}
                  className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="bg-[#241763] text-white px-4 py-2 rounded-md"
                >
                  Post Job
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Recruitment;
