import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Pencil, Trash2, Plus, ChevronUp, ChevronDown } from "lucide-react";
import ApplyLeaveModal from "../../modals/Hrms/ApplyLeaveModal";
import AssignLeaveModal from "../../modals/Hrms/AssignLeaveModal";
const LeaveDetails = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, reset, setValue } = useForm();
  const [isApplyLeaveModalOpen, setIsApplyLeaveModalOpen] = useState(false);
  const [isAssignLeaveModalOpen, setIsAssignLeaveModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentWarning, setCurrentWarning] = useState(null);

  const [warnings, setWarnings] = useState([]);

 

  const onApplyLeaveSubmit = (data) => {
    const newLeave = {
      id: Date.now(),
      name: data.employeeName,
      leaveType: data.leaveType,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo || data.dateFrom,
      reason: data.reason,
    };

    setWarnings((prev) => [...prev, newLeave]);
    toast.success("Leave applied successfully!");
    setIsApplyLeaveModalOpen(false);
    reset();
  };

  const onAssignLeaveSubmit = (data) => {
    // You can handle the logic for assigned leave submission here
    console.log("Assigned Leave Data:", data);
    toast.success("Leave assigned successfully!");
    setIsAssignLeaveModalOpen(false);
    reset();
  };



  return (
    <div className="container mx-auto p-4 border border-[#241763]">
      {/* Dashboard Cards */}

      <div className="flex flex-col sm:flex-row sm:items-start sm:justify-start sm:space-x-4 w-full mb-4 space-y-4 sm:space-y-0">
      <button
          className="w-full sm:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsEdit(false);
            setIsApplyLeaveModalOpen(true);
            reset();
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2 text-sm">Apply Leave</span>
        </button>
        <button
          className="w-full sm:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => {
            setIsEdit(false);
            setIsAssignLeaveModalOpen(true);
            reset();
          }}
        >
          <Plus className="h-4 w-4" />
          <span className="ml-2 text-sm">Assign Leave</span>
        </button>

        <button
          className="w-full sm:w-auto flex items-center justify-center bg-[#241763] text-white p-2 rounded"
          onClick={() => navigate("/employee_all_leaves")} // Navigate to AllLeaveDetails
        >
          <span className="ml-2 text-sm">View Request</span>
        </button>
      
      </div>

      {/* Filters and Add Button */}
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center w-full">
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">Leave Type</option>
          {/* Add location options */}
        </select>
     
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Departments</option>
          {/* Add location options */}
        </select>
     
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Designation</option>
          {/* Add location options */}
        </select>
     
        <select
          className="p-2 border border-gray-300 rounded w-full md:w-2/6"
          // value={locationFilter}
          // onChange={(e) => setLocationFilter(e.target.value)}
        >
          <option value="">All Locations</option>
          {/* Add location options */}
        </select>
     
      </div>

      {/* Warning List Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full mt-6">
          <thead className="py-4 border-b font-bold">
            <tr>
              <th>Emp. Name</th>
              <th>Leave Type</th>
              <th>Date From</th>
              <th>Date To</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {warnings.map((warning, index) => (
              <React.Fragment key={warning.id}>
                <tr className="p-2 py-2 hover:bg-gray-200 space-y-2">
                <td className="py-4">{warning.name}</td>
                  <td className=" capitalize">
                    {warning.leaveType.replace(/([A-Z])/g, " $1").trim()}
                  </td>
                  <td className="">{warning.dateFrom}</td>
                  <td className="">{warning.dateTo}</td>
                  <td className="">{warning.reason || "N/A"}</td>
                </tr>
             
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* Warning Modal */}
       {/* Apply Leave Modal */}
      <ApplyLeaveModal
        isModalOpen={isApplyLeaveModalOpen}
        isEdit={isEdit}
        onSubmit={onApplyLeaveSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsApplyLeaveModalOpen}
        register={register}
        reset={reset}
      />

      {/* Assign Leave Modal */}
      <AssignLeaveModal
        isModalOpen={isAssignLeaveModalOpen}
        isEdit={isEdit}
        onSubmit={onAssignLeaveSubmit}
        handleSubmit={handleSubmit}
        setIsModalOpen={setIsAssignLeaveModalOpen}
        register={register}
        reset={reset}
      />

      <Toaster />
    </div>
  );
};

export default LeaveDetails;
