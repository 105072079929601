import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";

const FeeStructureManagementModal = ({
  isOpen,
  onClose,
  onSave,
  editingData,
  classes,
  courses,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const {
    fields: discountFields,
    append: addDiscount,
    remove: removeDiscount,
  } = useFieldArray({
    control,
    name: "discounts",
  });

  const {
    fields: scholarshipFields,
    append: addScholarship,
    remove: removeScholarship,
  } = useFieldArray({
    control,
    name: "scholarship",
  });

  const [selectedType, setSelectedType] = useState("Courses");

  useEffect(() => {
    if (editingData) {
      reset({
        ...editingData,
        courseId: editingData.courseId || '',
        classId: editingData.classId || '',
      });
      setSelectedType(editingData.type || "Courses");
    }
  }, [editingData, reset]);
  

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      feeCode: data.feeCode,
      classId: selectedType === "Classes" ? data.classId : null,
      courseId: selectedType === "Courses" ? data.courseId : null,
      mode: data.mode,
      tutionFee: parseFloat(data.tutionFee) || 0,
      labFee: parseFloat(data.labFee) || 0,
      equipmentFee: parseFloat(data.equipmentFee) || 0,
      libraryFee: parseFloat(data.libraryFee) || 0,
      sportsFee: parseFloat(data.sportsFee) || 0,
      lmsFee: parseFloat(data.lmsFee) || 0,
      admissionFee: parseFloat(data.admissionFee) || 0,
      securityFee: parseFloat(data.securityFee) || 0,
      mealFee: parseFloat(data.mealFee) || 0,
      taxesFee: parseFloat(data.taxesFee) || 0,
      schoolFines: parseFloat(data.schoolFines) || 0,
      latePaymentFine: parseFloat(data.latePaymentFine) || 0,
      discounts: data.discounts || [],
    };

    console.log("Final Payload for API:", payload);
    onSave(payload); // Replace with your API function
    reset(); // Reset the form
    onClose(); // Close the modal
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleAddDiscount = () =>
    addDiscount({ discountName: "", discountPercentage: "" });
  const handleAddScholarship = () =>
    addScholarship({ scholarshipName: "", scholarshipPercentage: "" });

  const handleRemoveDiscount = (index) => removeDiscount(index);
  const handleRemoveScholarship = (index) => removeScholarship(index);

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-3/4 max-h-[90vh] overflow-y-auto rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between  mb-4 pb-4 border-b">
          <h2 className="text-2xl font-semibold ">Fee Structure Management</h2>
          <span
            type="button"
            onClick={onClose}
            className="bg-[#241763] text-white px-3 py-2 rounded"
          >
            X
          </span>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-4 gap-6">
            <div>
              <label className="block mb-1 font-medium">
                Fee Structure Name
              </label>
              <input
                {...register("name", {
                  required: "This field is required",
                })}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter Fee Structure Name"
              />
              {errors.feeStructureName && (
                <p className="text-red-500">
                  {errors.feeStructureName.message}
                </p>
              )}
            </div>
            <div className=" col-span-2">
              <label className="block mb-1 font-medium">Description</label>
              <input
                {...register("description")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter Description"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Fee Code</label>
              <input
                {...register("feeCode")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter Fee Code"
                type="string"
              />
            </div>
          </div>
          {/* Row 2 */}
          <div className="grid grid-cols-4 gap-6">
            <div>
              <label className="block mb-1 font-medium">
                Courses / Classes
              </label>
              <select
                className="w-full border border-gray-300 rounded p-2"
                {...register("type")}
                value={selectedType}
                onChange={handleTypeChange}
              >
                <option value="Courses">Courses</option>
                <option value="Classes">Classes</option>
              </select>
            </div>
            <div>
              <label className="block mb-1 font-medium">
                {selectedType === "Classes" ? "Class Name" : "Course Name"}
              </label>
              <select
                className="w-full border border-gray-300 rounded p-2"
                {...register(
                  selectedType === "Classes" ? "classId" : "courseId"
                )}
              >
                {(selectedType === "Classes" ? classes : courses)?.map(
                  (item) => (
                    <option key={item.id} value={item.id}>
                      {selectedType === "Classes" ? item.className : item.name}
                    </option>
                  )
                )}
              </select>
            </div>

            <div>
              <label className="block mb-1 font-medium">Academic Year</label>
              <input
                {...register("academicYear")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter Academic Year"
                type="date"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">
                Monthly / One Time
              </label>
              <input
                {...register("mode")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Monthly / One Time"
              />
            </div>
          </div>

          {/* Row 3 */}
          <h1 className="font-bold text-xl">Fee Component</h1>
          <div className="grid grid-cols-4 gap-6">
            <div>
              <label className="block mb-1 font-medium">Tuition Fee</label>
              <input
                {...register("tutionFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter Tuition Fee"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Billing Cycle</label>
              <input
                {...register("billingCycle")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Enter Billing Cycle"
              />
            </div>
          </div>

          {/* Additional Charges */}
          <h1 className="font-bold text-xl">Additional Charges</h1>
          <div className="grid grid-cols-4 gap-6">
            <div>
              <label className="block mb-1 font-medium">
                Lab / Equipment Fee
              </label>
              <input
                {...register("labFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Lab / Equipment Fee"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Library Fee</label>
              <input
                {...register("libraryFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Library Fee"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">
                Sports Extra Curricular Fee
              </label>
              <input
                {...register("sportsFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Sports Fee"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">
                Software / LMS Charges
              </label>
              <input
                {...register("lmsFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Software / LMS Charges"
              />
            </div>
          </div>

          {/* Row 4 */}
          <div className="grid grid-cols-4 gap-6">
            <div>
              <label className="block mb-1 font-medium">Admission Fee</label>
              <input
                {...register("admissionFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Admission Fee"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Security deposit</label>
              <input
                {...register("securityFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="security Deposit"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Optional Fee</label>
              <input
                {...register("optionalFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Optional Fee"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Meal Plan</label>
              <input
                {...register("mealFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Meal Plan"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Fines</label>
              <input
                {...register("fines")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Fines"
              />
            </div>
          </div>

          {/* Discounts */}
          <div className="flex items-center justify-start space-x-6">
            <h1 className="font-bold text-xl">
              Discount Name and Discount %age
            </h1>
            <button
              type="button"
              onClick={handleAddDiscount}
              className="bg-[#241763] text-white px-4 py-2 rounded"
            >
              + Add Discount
            </button>
            <button
              type="button"
              onClick={handleAddScholarship}
              className="bg-[#241763] text-white px-4 py-2 rounded"
            >
              + Add Scholarship
            </button>
          </div>
          <div>
            <label className="block mb-1 font-medium">Special Discount</label>
            <input
              {...register("specialDiscount")}
              className="w-full border border-gray-300 p-2 rounded"
              placeholder="Special disc"
            />
          </div>
          <div className="space-y-4 mt-4">
            {discountFields.map((field, index) => (
              <div
                key={field.id}
                className="grid grid-cols-3 gap-4 items-center"
              >
                <div>
                  <label className="block mb-1 font-medium">
                    Discount Name {index + 1}
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your discount"
                    {...register(`discounts.${index}.name`, {})}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
                <div>
                  <label className="block mb-1 font-medium">
                    Discount Percentage
                  </label>
                  <input
                    type="text"
                    placeholder="enter %"
                    {...register(`discounts.${index}.discountPercentage`, {})}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
                <span
                  onClick={() => handleRemoveDiscount(index)}
                  className="cursor-pointer text-red-500 mt-4 font-bold text-lg"
                >
                  x
                </span>
              </div>
            ))}
          </div>

          <hr className="border-t border-gray-400 my-6" />
          <div className="space-y-4 mt-4">
            {scholarshipFields.map((field, index) => (
              <div
                key={field.id}
                className="grid grid-cols-3 gap-4 items-center"
              >
                <div>
                  <label className="block mb-1 font-medium">
                    Scholarship Name {index + 1}
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Scholarship Name"
                    {...register(`scholarship.${index}.scholarshipName`, {
                      required: "Scholarship Name is required",
                    })}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
                <div>
                  <label className="block mb-1 font-medium">
                    Scholarship Percentage
                  </label>
                  <input
                    {...register(`scholarship.${index}.scholarshipPercentage`, {
                      required: "Scholarship Percentage is required",
                    })}
                    className="w-full border border-gray-300 p-2 rounded"
                    type="text"
                    placeholder="enter %"
                  />
                </div>
                <span
                  onClick={() => handleRemoveScholarship(index)}
                  className="cursor-pointer text-red-500 mt-4 font-bold text-lg"
                >
                  x
                </span>
              </div>
            ))}
          </div>

          {/* Payment Details */}
          <h1 className="font-bold text-xl"> Payment Details</h1>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <label className="block mb-1 font-medium">Totol Fee</label>
              <input
                {...register("totalFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Total fee"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">
                Late Payment Fine
              </label>
              <input
                {...register("latePaymentFine")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Late Payment Fine"
              />
            </div>

            <div>
              <label className="block mb-1 font-medium">School Fines</label>
              <input
                {...register("schoolFines")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="School Fines"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Taxes</label>
              <input
                {...register("taxesFee")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Taxes"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium">Total Paybale</label>
              <input
                {...register("totalPaybale")}
                className="w-full border border-gray-300 p-2 rounded"
                placeholder="Total Paybale"
              />
            </div>
          </div>

          {/* Submit and Close Buttons */}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="submit"
              className="bg-[#241763] text-white px-6 py-2 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeeStructureManagementModal;
