import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getRequest } from "../services";
import { userAvatar } from "../Constant/images";
import { pencilSvg } from "../Constant/svgs";
import UserMeta from "../Components/Common/UserMeta";
import ClassDetails from "./ClassesDetails";
import ChangePassword from "./ChangePassword";

const UserGenInfoTeacher = () => {

  const [genInfo, setGenInfo] = useState({
    first_name: "",
    last_name: "",
    designation: "",
    email: "",
    phone: "",
    city: "",
    address: "",
    cnic: "",
    salary: "",
    birth_date: null,
  });
  const auth = JSON.parse(localStorage.getItem("user"));
  const [avatar, setAvatar] = useState(userAvatar);

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language;

  // Update genInfo with fetched user data
  useEffect(() => {
    const fetchDataUser = async () => {
      try {
        const response = await getRequest(`/api/User/GetUserById/${auth.userId}`);
        const data = response.data.data;

        setGenInfo({
          first_name: data.firstName || "",
          last_name: data.lastName || "",
          designation: data.userType || "",
          email: data.email || "",
          phone: data.phone || "",
          city: data.location || "",
          address: "",
          cnic: data.cnic || "",
          salary: "",
          birth_date: data.dob
        });

        // Update avatar if profile picture exists
        if (data.profilePicture) {
          setAvatar(data.profilePicture);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchDataUser();
  }, [auth.userId]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGenInfo({ ...genInfo, [name]: value });
  };

  const handleSubmit = () => {
    console.log("Submitting Updated Data:", genInfo);
    // Handle form submission logic here
  };
  return (
    <>
      <div className="col-md-4">
        <div className="w-100 userProfileWrapper py-4">
          <div className="userMeta w-100 d-flex align-items-center flex-column gap-2 mt-2">
            <div className="avatarWrapper">
              <img
                src={avatar}
                alt="user"
                className="img-fluid rounded-circle"
              />
              <div className="editAvatarImage">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="fileInput"
                />
                <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                  {pencilSvg}
                </label>
              </div>
            </div>
            <h4 className="fw-bold fs-6">{`${genInfo.first_name} ${genInfo.last_name}`}</h4>
            <h6 className="text-secondary">{t(auth.user)}</h6>
          </div>
          <UserMeta />
        </div>
      </div>
      <div className="col-md-8">
        <div className="settings w-100 d-flex flex-column gap-3">

          <div className="w-100 py-4 px-4 d-flex flex-column userGenInfoWrapper gap-3">
            <h2 className="heading24px fw-bold text-center">{t("GeneralInformation")}</h2>
            <div className={`row studentProfileHeading ${isArabic === "sa" ? "flex-row-reverse" : ""}`}>

              <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="firstName" className="form-label">
                  {t("FirstName")}
                </label>
                <div className="mb-3">
                  <input
                    type="text"
                    placeholder="Peter"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="first_name"
                    onChange={handleChange}
                    value={genInfo.first_name}
                  />
                </div>
              </div>

              <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="lastName" className="form-label">
                  {t("LastName")}
                </label>
                <div className="mb-3">
                  <input
                    type="text"
                    placeholder="Smith"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="last_name"
                    onChange={handleChange}
                    value={genInfo.last_name}
                  />
                </div>
              </div>

              <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="designation" className="form-label">
                  {t("Designation")}
                </label>
                <div className="mb-3">
                  <input
                    type="text"
                    disabled
                    placeholder="Ass Professor"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="designation"
                    onChange={handleChange}
                    value={genInfo.designation}
                  />
                </div>
              </div>

              <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="email" className="form-label">
                  {t("Email")}
                </label>
                <div className="mb-3">
                  <input
                    type="email"
                    disabled
                    placeholder="hello@designdrops.io"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="email"
                    onChange={handleChange}
                    value={genInfo.email}
                  />
                </div>
              </div>

              <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="phone" className="form-label">
                  {t("Phone")}
                </label>
                <div className="mb-3">
                  <input
                    type="number"
                    placeholder="+92"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="phone"
                    onChange={handleChange}
                    value={genInfo.phone}
                  />
                </div>
              </div>

              <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="cnic" className="form-label">
                  {t("CNIC")}
                </label>
                <div className="mb-3">
                  <input
                    type="text"
                    disabled
                    placeholder="12345-1234567-1"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="cnic"
                    onChange={handleChange}
                    value={genInfo.cnic}
                  />
                </div>
              </div>

              <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="salary" className="form-label">
                  {auth.user === "student" ? t("Fees") : t("Salary")}
                </label>
                <div className="mb-3">
                  <input
                    disabled
                    type="number"
                    placeholder="10,000"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="salary"
                    onChange={handleChange}
                    value={genInfo.salary}
                  />
                </div>
              </div>

              <div className={`col-md-6 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="city" className="form-label">
                  {t("City")}
                </label>
                <div className="mb-3">
                  <input
                    type="text"
                    placeholder="City"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="city"
                    onChange={handleChange}
                    value={genInfo.city}
                  />
                </div>
              </div>

              <div className={`col-md-12 ${isArabic === "sa" ? 'text-end' : 'text-start'}`}>
                <label htmlFor="address" className="form-label">
                  {t("Address")}
                </label>
                <div className="mb-3">
                  <textarea
                    placeholder="Address"
                    className={`form-control ${isArabic === "sa" ? 'text-end' : 'text-start'}`}
                    name="address"
                    onChange={handleChange}
                    value={genInfo.address}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-center mt-2">
                  <button className="btn-update" onClick={handleSubmit}>
                    {t("SaveChanges")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {auth.user !== "parent" ? <ClassDetails /> : null}
        <ChangePassword />
      </div>
    </>
  );
};

export default UserGenInfoTeacher;
