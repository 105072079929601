import React from 'react';

const JobModal = ({ job, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 p-6 overflow-y-auto max-h-full">
        <h2 className="text-2xl font-bold mb-4">{job.title}</h2>
        <p className="text-gray-700 mb-2"><strong>Vacancies:</strong> {job.vacancies}</p>
        <p className="text-gray-700 mb-2"><strong>Last Date to Apply:</strong> {job.lastDate}</p>
        <p className="text-gray-700 mb-4"><strong>Description:</strong> {job.description}</p>
        <p className="text-gray-700 mb-2"><strong>Educational Requirement:</strong> {job.educationalRequirement}</p>
        <p className="text-gray-700 mb-2"><strong>Skill Set:</strong> {job.skillSet}</p>
        <p className="text-gray-700 mb-4"><strong>Duties and Responsibilities:</strong> {job.dutiesAndResponsibilities}</p>
        <button
          className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default JobModal;
