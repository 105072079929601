import { Button, Tag } from 'antd';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

const NotificationsModal = ({ selectedItems, toggleItemSelection, handleSubmitNotification, onClose }) => {
  const [notificationBody, setNotificationBody] = useState("");
  const [attachment, setAttachment] = useState(null);

	const onSubmit = () => {
		if (selectedItems.length === 0) {
			toast.error('Please select someone to send notification!');
      return;
    }
		if (!notificationBody.trim()) {
			toast.error('Add some text to send notification!');
      return;
    }

		setNotificationBody("");
		const payload = new FormData();
		payload.append("items", selectedItems?.map(item => item?.id));
		payload.append("Notification", notificationBody);
		payload.append("attachment", attachment);
		handleSubmitNotification(payload);
		toast.success("Notifications sent!");
		onClose();
	}

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			<div className="p-6 bg-white shadow-md mx-auto mt-20 rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
				<h2 className="text-lg font-bold mb-4">Send Notification</h2>
				<p className="mb-2 font-semibold">Selected Persons:</p>
				<ul className="mb-4">
					{selectedItems?.map((item) => {
						return (
						<Tag
							closable
							key={item.id}
							onClose={(e) => {
								e.preventDefault();
								toggleItemSelection(item.id, item.name)
							}}
						>
							{item.name}
						</Tag>
						);
					})}
				</ul>
				<textarea
					rows={4}
					placeholder="Write your notification message here..."
					value={notificationBody}
					onChange={(e) => setNotificationBody(e.target.value)}
					className='w-full p-2'
				/>
				<input
					type="file"
					id="contentUpload"
					className='w-full p-2'
					onChange={(e) => {
							const file = e.target.files[0];
							if (file) {
								setAttachment(file);
							}
						}
					}
				/>
				<div className="flex justify-end space-x-2 mt-4">
					<Button variant="outlined" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={onSubmit}
					>
						Send Notification
					</Button>
				</div>
			</div>
		</div>
  );
};

export default NotificationsModal;