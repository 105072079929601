import { createContext, useContext, useState, useEffect } from "react";
import { getRequest } from "../services";

const ParentContext = createContext();
const getRandomNumber = (min = 0, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const ParentContexProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = JSON.parse(localStorage.getItem("user"));
  const userId = auth.userId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest(`/api/Parent/${userId}`);
        const data = response.data?.data?.childerns || [];
        const formattedUsers = data.map((child, index) => ({
          id: child.id,
          name: child.name,
          avatar: child.profilePicture,
          class: `class ${index}`,
          percentage: "0",
          isActive: index === 0,
          singleData: Array.from({ length: 12 }, (_, i) => ({
            month: new Date(0, i).toLocaleString("en", { month: "short" }),
            percentage: 0,
          })),
          fees: [
            {
              name: "Jennifer Markus",
              id: "01234",
              payable: "1,500.00",
              paid: "500.00",
              pending: "3,500.00",
              description: "Fees",
            },
            {
              name: "Jennifer Markus",
              id: "01234",
              payable: "4,500.00",
              paid: "5,500.00",
              pending: "6,500.00",
              description: "Fine",
            },
            {
              name: "Jennifer Markus",
              id: "01234",
              payable: "4,500.00",
              paid: "2,500.00",
              pending: "2,500.00",
              description: "Charges",
            },
          ],
          graphData: [
            {
              month: "Jan",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "Feb",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "Mar",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "Apr",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "May",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "Jun",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "July",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
      
            {
              month: "Aug",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "Sep",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "Oct",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "Nov",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
            {
              month: "Dec",
              assesment: getRandomNumber(),
              attendance: getRandomNumber(),
              performance: getRandomNumber(),
            },
          ],
          pieData: [
            { name: "English", value: 400 },
            { name: "Maths", value: 300 },
            { name: "Biology", value: 300 },
            { name: "Physics", value: 200 },
          ],
        }));
        setUsers(formattedUsers);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <ParentContext.Provider value={{ users, setUsers, loading }}>
      {children}
    </ParentContext.Provider>
  );
};

export const useParentContext = () => useContext(ParentContext);
