import React, { useState } from "react";
import { Pencil, Trash2, ChevronDown, ChevronUp } from "lucide-react";
import FeeStructureManagementModal from "../../../modals/admin/FeeStructureManagementModal";

function GeneratedSalaries({ feeStructures, setFeeStructures }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const [searchText, setSearchText] = useState("");

  // Handle HR Approval Button Click
  const handleHRApproval = () => {
    // Example logic - Replace with actual functionality
    console.log("Proceeding for HR Approval...");
    alert("Proceeding for HR Approval...");
  };

  // Handle Salaries Detail Button Click
  const handleSalariesDetail = () => {
    // Example logic - Replace with actual functionality
    console.log("Viewing Salaries Detail...");
    alert("Viewing Salaries Detail...");
  };

  // Handle Search Input Change
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    console.log("Searching:", event.target.value);
  };

  const handleAddNew = () => {
    setEditingData(null);
    setIsModalOpen(true);
  };

  const handleSave = (data) => {
    if (editingData) {
      const updated = feeStructures.map((item, index) =>
        index === editingData.index ? data : item
      );
      setFeeStructures(updated);
    } else {
      setFeeStructures([...feeStructures, data]);
    }
    setIsModalOpen(false);
    setEditingData(null);
  };

  const handleEdit = (index) => {
    setEditingData({ ...feeStructures[index], index });
    setIsModalOpen(true);
  };

  const handleDelete = (index) => {
    setFeeStructures(feeStructures.filter((_, i) => i !== index));
  };

  const toggleView = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  return (
    <div>
      <div className="flex w-full items-center justify-between mt-4 space-x-2">
        <span className="text-gray-700 font-medium">
          Session: February-2024
        </span>
        <input
          type="text"
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Search By Emp. Name / Code"
          className="border border-gray-300 rounded p-2 w-1/4"
        />
        <button
          onClick={handleHRApproval}
          className="px-4 py-2 bg-[#241763] text-white rounded"
        >
          Proceed for HR Approval
        </button>
        <button
          onClick={handleSalariesDetail}
          className="px-4 py-2 bg-[#241763] text-white rounded"
        >
          Salaries Detail
        </button>
      </div>

      <div className="overflow-x-auto mt-6">
        <table className="table-auto w-full mt-2 text-sm">
          <thead className=" border-b font-bold">
            <tr className=" border-b bg-[#241763]/10 ">
              <th className="p-2">Emp code</th>
              <th>Emp Name</th>
              <th>Session</th>
              <th>T.days</th>
              <th>P</th>
              <th>A</th>
              <th>Late</th>
              <th>Leaves</th>
              <th>Ded.</th>
              <th>OT</th>
              <th>Ded.</th>
              <th>B.Salary</th>
              <th>Ded.Amount</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {feeStructures.length > 0 ? (
              feeStructures.map((fee, index) => (
                <React.Fragment key={index}>
                  <tr className="hover:bg-gray-200">
                    <td>{fee.feeStructureName}</td>
                    <td>{fee.description}</td>
                    <td>{fee.feeCode}</td>
                    <td>{fee.tuitionFee}</td>
                    <td>{fee.labFee}</td>
                    <td>{fee.academicYear}</td>
                    <td className="space-x-2 flex items-center py-2">
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleEdit(index)}
                      >
                        <Pencil className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <button
                        className="bg-[#B6A5C9]/50 text-white p-2 rounded hover:bg-[#241763]/60 group"
                        onClick={() => handleDelete(index)}
                      >
                        <Trash2 className="text-[#241763] w-4 h-4 group-hover:text-white" />
                      </button>
                      <div
                        onClick={() => toggleView(index)}
                        className="cursor-pointer bg-[#B6A5C9]/50 p-2 rounded hover:bg-[#241763]/60 group"
                      >
                        {expandedRowIndex === index ? (
                          <ChevronUp
                            size={16}
                            className="group-hover:text-white"
                          />
                        ) : (
                          <ChevronDown
                            size={16}
                            className="group-hover:text-white"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No Fee Structures Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <FeeStructureManagementModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
          editingData={editingData}
        />
      )}
    </div>
  );
}

export default GeneratedSalaries;
