import React from 'react'

const Hrms = () => {
  return (
    <div>
      Welcome to HRMS
    </div>
  )
}

export default Hrms
